import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../redux/actions';
import { format, parseISO } from 'date-fns';
import styles from './ItemAnalysisReport.module.scss';
import WaitForIt from '../../WaitForIt';
import ReportTable from '../../ReportTable';
import ReportHeader from '../../ReportHeader';
import ErrorMessage from '../../ErrorMessage';
import { CSVLink } from 'react-csv';
import Images from './../../../assets/Images';
import ProgramSelector from '../../ProgramSelector'


function ItemAnalysisReport() {
  const userUUID = useSelector(state=>state.userUUID);
  const selectedProgram = useSelector(state=>state.program);
  const loading = useSelector(state=>state.loading);
  const error = useSelector(state=>state.error);
  const reportData = useSelector(state=>state.reportData);
  const dispatch = useDispatch();

  const [selectedTest, setSelectedTest] = useState();

  const loadReport = (practiceTest) => {
    setSelectedTest(practiceTest);
    dispatch(allActions.getItemAnalysisReport(userUUID, selectedProgram, selectedTest));
  }

  useEffect(() => {
    if (selectedTest)
      loadReport(selectedTest);
  }, [selectedTest]);

  const reportParameters = 
    <div className={styles.parametersMenu}>
      <div className={styles.parameters}> 
        { selectedProgram.completedPracticeTests.length === 0 
          ? <div>no practice tests found</div>
          : selectedProgram.completedPracticeTests.map((value, index) => 
            <button key={index} onClick={(e)=>setSelectedTest(value.practiceTest)} className={styles.practiceTestParam}>
              {value.practiceTest}- {format(parseISO(value.date), 'M/d/yy')}
            </button>)
        }
      </div>
    </div>
  
  const Report = (
    reportData ? 
      <div className={styles.reportContainer}>
        <div className={styles.reportButtons}>
          <CSVLink 
            data={reportData.rows} 
            headers={reportData.headers}
            filename={selectedProgram.name+'_'+selectedTest+'.csv'}
            className={styles.exportButton}>
            export
          </CSVLink>
          <img src={Images.export} alt='export report'></img>
        </div>
        <ReportHeader 
          reportTitle='Item Analysis'
          programName={reportData.reportHeader.program}
          testName={reportData.reportHeader.practiceTest} />
        <div className={styles.reportScrollView}>
          <ReportTable
            headers={reportData.headers}
            data={reportData.rows} />
        </div>
      </div>
    : null);
    
  return (
    <div className={styles.itemAnalysisReport}>
      <ProgramSelector /> 
      <div className={styles.pageTitle}>Item Analysis Report</div>
      {reportParameters}
      { loading ? 
        <div className={styles.reportLoading}>
          <WaitForIt waitingMessage='Running Report'></WaitForIt>
        </div>
        : null
      }
      { error ? 
        <div className={styles.reportLoadError}>
          <div className={styles.header}>There was a problem loading your report</div>
            <ErrorMessage errors={error} />
        </div>
        :null
      }
      {Report}
    </div>
  );
}

export default ItemAnalysisReport;