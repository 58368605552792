import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WaitForIt from '../../WaitForIt';
import ErrorMessage from '../../ErrorMessage';
import { getClassSummaryReport } from '../../../redux/actions';
import ProgramSelector from '../../ProgramSelector';
import Images from './../../../assets/Images';

import styles from './GrowthReport.module.scss';

class GrowthReport extends React.Component {
  state = {
    showParameters: true,
    practiceTests: ['SATTest5'],
  };

  componentDidMount() {
    this.loadReport();
  }

  componentDidUpdate(prevProps) {
    if(this.props.program.ID !== prevProps.program.ID) {
      this.loadReport();
    }
  }


  loadReport = (e) => {
    const { userUUID, program } = this.props;

    this.props.getClassSummaryReport(userUUID, program.ID);

    this.setState({showParameters: false});
  }

  generateTable(test) {
    let students = [];
    let averages = {};
    if(test.testType === 'ACT') {
      averages['total'] = 0;
      averages['english'] = 0;
      averages['science'] = 0;
      averages['math'] = 0;
      averages['reading'] = 0;
    }
    else {
      averages['total'] = 0;
      averages['reading_writing'] = 0;
      averages['math'] = 0;
    }
    for(let student of test.students) {
      if(test.testType === 'ACT') {
        students.push((
          <div className={styles.tableRow}>
            <div>{student.studentName}</div>
            <div>{student.total}</div>
            <div>{student.english}</div>
            <div>{student.science}</div>
            <div>{student.math}</div>
            <div>{student.reading}</div>
          </div>
        ));
        averages['total'] += student.total ? parseInt(student.total):0;
        averages['english'] += student.english ? parseInt(student.english):0;
        averages['science'] += student.science ? parseInt(student.science):0;
        averages['math'] += student.math ? parseInt(student.math):0;
        averages['reading'] += student.reading ? parseInt(student.reading):0;
      }
      else {
        students.push((
          <div className={styles.tableRow}>
            <div>{student.studentName}</div>
            <div>{student.total}</div>
            <div>{student.reading_writing}</div>
            <div>{student.math}</div>
          </div>
        ))
        averages['total'] += student.total ? parseInt(student.total):0;
        averages['reading_writing'] += student.reading_writing ? parseInt(student.reading_writing):0;
        averages['math'] += student.math ? parseInt(student.math):0;
      }
    }
    if(test.testType === 'ACT') {
      return (
        <div className={styles.table}>
          <div className={styles.tableHead}>{test.testName}</div>
          <div className={styles.rowLabels}>
            <div>Student Name</div>
            <div>Total</div>
            <div>English</div>
            <div>Science</div>
            <div>Math</div>
            <div>Reading</div>
          </div>
          {students}
          <div className={styles.averageRow}>
            <div>Average</div>
            <div>{Math.round(averages.total / students.length)}</div>
            <div>{Math.round(averages.english / students.length)}</div>
            <div>{Math.round(averages.science / students.length)}</div>
            <div>{Math.round(averages.math / students.length)}</div>
            <div>{Math.round(averages.reading / students.length)}</div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className={styles.table}>
          <div className={styles.tableHead}>{test.testName}</div>
          <div className={styles.rowLabels}>
            <div>Student Name</div>
            <div>Total</div>
            <div>EBRW</div>
            <div>Math</div>
          </div>
          {students}
          <div className={styles.averageRow}>
            <div>Average</div>
            <div>{Math.round(averages.total / students.length)}</div>
            <div>{Math.round(averages.reading_writing / students.length)}</div>
            <div>{Math.round(averages.math / students.length)}</div>
          </div>
        </div>
      );
    }
  }

  // renderReportParameters() {
  //   const { showParameters } = this.state;
  //   const { reportFilters } = this.props;

  //   if (showParameters)
  //   {
  //     return (
  //       <div className={styles.parametersMenu}>
  //         <div className={styles.parameters}>
            
  //           <Selector options={reportFilters.practicetests} />
  //         </div>
  //         <div className={styles.dropdown}>
  //           {/* <button className={styles.showMenuButton}>
  //             <img src={Images.downArrow} alt="program drop down" onClick={this.handleChange} />
  //           </button> */}
  //           <Button onClick={this.loadReport}>run</Button>
  //         </div>
  //       </div>
  //     )
  //   }
  //   else {

  //   }
  // }

  renderReport() {

    const { reportData, loading, error, testType } = this.props;

    if (loading) {
      return (
        <div className={styles.reportLoading}>
          <WaitForIt waitingMessage='Running Report'></WaitForIt>
        </div>
      );
    }

    if (error) {
      return (
        <div className={styles.reportLoadError}>
          <div className={styles.header}>There was a problem loading your report</div>
          <ErrorMessage errors={this.props.error} />
        </div>
      );
    }

    if (reportData) {

      let testTables = [];
      if (testType === 'ACT') {
        for(let test of reportData['ACT']) {
          testTables.push(this.generateTable(test))
        }
      }
      else {
        for(let test of reportData['NEW_SAT']) {
          testTables.push(this.generateTable(test))
        }
      }

      if(testTables.length === 0) {
        return (
          <div className={styles.growthTable}>
            <div className={styles.header}>No tests found for this program</div>
          </div>
        );
      }

      return (
        <div className={styles.growthTable}>
          <div className={styles.header}>{testType}</div>
          {testTables}
        </div>
      );
    }
  }
  
  render() {
  
    return (
      <div className={styles.growthReport}>
        <ProgramSelector />
        <div className={styles.pageTitle}>Class Summary Report</div>
        <div className={styles.reportButtons}>
          <a
            onClick={() => window.print()}
            className={styles.exportButton}>
            print report 
          </a>
          <img src={Images.export} alt='export report'></img>
        </div>
        {/* {this.renderReportParameters()} */}
        {this.renderReport()}
      </div>
    );

  }
}

GrowthReport.propTypes = {
  program: PropTypes.object.isRequired,
  testType: PropTypes.string.isRequired,
  getClassSummaryReport: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  reportData: PropTypes.object,
  error: PropTypes.string,
  userUUID: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return { 
    program: state.program,
    loading: state.loading,
    userUUID: state.userUUID,
    error: state.error,
    reportData: state.reportData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClassSummaryReport: (userUUID, programID) => dispatch(getClassSummaryReport(userUUID, programID))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GrowthReport);
