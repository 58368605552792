import React from 'react';
import PropTypes from 'prop-types';

import styles from './QuestionLogHeader.module.scss';

function QuestionLogHeader(props) {
  const { questionDate, subject, question, reviewNote } = props;

  return (
    <div className={styles.questionLogHeader}>
      <div className={styles.questionDate}>{questionDate}</div>
      <div className={styles.subject}>{subject}</div>
      <div className={styles.question}>{question}</div>
      <div className={styles.review}>{reviewNote}</div>
    </div>
  );
}

QuestionLogHeader.propTypes = {
  questionDate: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  correct: PropTypes.string.isRequired,
  reviewNote: PropTypes.string.isRequired
};

export default QuestionLogHeader;
