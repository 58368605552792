import React from 'react';
import PropTypes from 'prop-types';

import styles from './ReportHeader.module.scss';
import Images from './../../assets/Images';

function ReportHeader(props) {

  const { reportTitle, programName, testName } = props;
  return (
    <div className={styles.reportHeader}>
      <img src={Images.appTitleLogo} alt='alist logo'></img>
      <div>{reportTitle}</div>
      <div className={styles.paramSummary}>
        <div>Class Name: {programName}</div>
        <div>Test Name: {testName}</div>
      </div>
    </div>
  );
}


ReportHeader.propTypes = {
  reportTitle: PropTypes.string.isRequired,
  programName: PropTypes.string.isRequired,
  testName: PropTypes.string.isRequired,
};

export default ReportHeader;