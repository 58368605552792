import React from 'react';
import PropTypes from 'prop-types';

import QuestionLogItem from '../StudentActivityLog/QuestionLogItem';
import QuestionLogHeader from '../StudentActivityLog/QuestionLogHeader';

import styles from './StudentActivityLog.module.scss';

function StudentActivityLog(props) {

  const { student, questions } = props;

  const items = questions.map(function(item, k) {
    return <QuestionLogItem
      key={k}
      questionDate = {item.questionDate}
      questionId= {item.questionId}
      subject= {item.category}
      correct= {item.correct}
      question= {item.questionBody}
      reviewedOn= {item.reviewedOn}
      reviewNote= {item.reviewNote}
    />;
  });

  const tableHeader = <QuestionLogHeader
    questionDate = 'Date'
    subject= 'Subject'
    correct= ' '
    question= 'Question'
    reviewNote= {student.displayName + '\'s Review Notes'}/>;

  return (
    <div className={styles.studentActivityLog}>
      {tableHeader}
      { (questions.length === 0) 
        ? <div className={styles.emptyLog}>Your student has not yet completed any questions.</div>
        : items
      }
    </div>
  );
}


StudentActivityLog.propTypes = {
  student: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired
};


export default (StudentActivityLog);
