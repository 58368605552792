import React from 'react';
import PropTypes from 'prop-types';

import styles from './DashboardCardQuestions.module.scss';

function DashboardCardQuestions(props) {
  const { total, recent } = props;
  return (
    <div className={styles.dashboardCardQuestions}>
      <div className={styles.title}>Questions Answered</div>
      <div className={styles.content}>
        <table>
          <tbody>
            <tr><td className={styles.value}>{recent}</td><td className={styles.value}>{total}</td></tr>
            <tr><td>last 7 days</td><td>all time</td></tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}


DashboardCardQuestions.propTypes = {
  total: PropTypes.number.isRequired,
  recent: PropTypes.number.isRequired,
};

export default DashboardCardQuestions;