import React from 'react';
import HomeLayoutContainer from '../HomeLayoutContainer';
import Dashboard from '../Dashboard';

function Home(){
  const dashboard = <Dashboard />;

  return (
    <HomeLayoutContainer rightSideContent={dashboard}/>
  );
}

export default Home;