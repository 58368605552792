import React from 'react';

import ReportLink from '../ReportLink';
import HomeLayoutContainer from '../HomeLayoutContainer';
import ItemAnalysisReport from '../Reports/ItemAnalysisReport';
import GrowthReport from '../Reports/GrowthReport';
// import styles from './ReportHome.module.scss';

class ReportHome extends React.Component {

  state = {
    selectedReport: undefined,
  };
 
  onReportSelect = report => {
    this.setState({selectedReport: report});
  };

  renderReportLinks() {
    return (
      <React.Fragment>
        <ReportLink 
          reportName='Item Analysis'
          reportDescription='the one that shows the percentage of students that choose specific answers for each question'
          reportID='ITEM_ANALYSIS'
          onSelect={this.onReportSelect}/>
        <ReportLink
          reportName='SAT Summary Report'
          reportDescription='the one that lists students and their SAT total and section scores'
          reportID='SAT_SUMMARY_REPORT'
          onSelect={this.onReportSelect}/>
        <ReportLink
          reportName='ACT Summary Report'
          reportDescription='the one that lists students and their ACT total and section scores'
          reportID='ACT_SUMMARY_REPORT'
          onSelect={this.onReportSelect}/>
      </React.Fragment>
    );
  }

  renderReport(selectedReport) {
    if (selectedReport === 'ITEM_ANALYSIS') {
      return (<ItemAnalysisReport />);
    }
    else if (selectedReport === 'ACT_SUMMARY_REPORT') {
      return (<GrowthReport testType='ACT' />);
    }
    else if (selectedReport === 'SAT_SUMMARY_REPORT') {
      return (<GrowthReport testType='SAT'/>);
    }
    else {
      console.log('renderReport ', selectedReport)
    }
  }

  render() {
    const { selectedReport } = this.state;

    const mainContent = (selectedReport) 
      ? this.renderReport(selectedReport) 
      : this.renderReportLinks();

    return (
      <HomeLayoutContainer rightSideContent={mainContent} showTopNavBar={false}/>
    );
  }
}

export default ReportHome;