import React from 'react';
import PropTypes from 'prop-types';

import ScoreSummary from '../Charts/ScoreSummary';
import { format, parseISO } from 'date-fns';

import styles from './StudentScoreSummary.module.scss';

function ScoreCard(props) {
  const { testType, scores } = props;
  const sections = (testType === 'ACT') 
    ? {
        english: {score:scores.english}, 
        reading: {score:scores.reading}, 
        math: {score:scores.math}, 
        science: {score:scores.science}}
      
    : {
        math: {score:scores.math}, 
        ela: {score:scores.reading_writing}};

  return (
    <div className={styles.scoreCard}>
      <ScoreSummary
        testID={testType}
        sections={sections}
        compositeScore={scores.composite}>
      </ScoreSummary>
    </div>
  );
}

function PracticeTestItem(props) {
  const { date, name, composite, url } = props;
  return (
    <tr className={styles.practiceTestRow}>
      <td>{format(parseISO(date),'M/d/yy')}</td>
      <td>{name}</td>
      <td>{composite}</td>
      <td><a className={styles.testLink} href={url}>details</a></td>
    </tr>
  );
}

function PracticeTests(props) {
  const { testHistory } = props;
  const appURL = 'https://app.testive.com'
  const practiceTestRows = testHistory.map(function(item, k) {

    return <PracticeTestItem
      key={k}
      date={item.date}
      name={item.name}
      composite={item.composite}
      url={appURL+item.url}
    />;
  });

  return (
    <div className={styles.practiceTests}>
      <div className={styles.title}>Practice Test History</div>
      <table className={styles.practiceTestTable}>
        <tbody>{practiceTestRows}</tbody>
      </table>

    </div>
  );
}

function StudentScoreSummary(props) {
  const { testType, practiceTestHistory, scores } = props;

  return (
    <div className={styles.studentScoreSummary}>
      <ScoreCard 
        testType={testType}
        scores={scores} />
      <PracticeTests testHistory={practiceTestHistory}/>
    </div>
  );
}

StudentScoreSummary.propTypes = {
  practiceTestHistory: PropTypes.array.isRequired,
  scores: PropTypes.object.isRequired,
  testType: PropTypes.string.isRequired,
};

export default StudentScoreSummary;