import React from 'react';
import PropTypes from 'prop-types';
import ScoreCircle from '../ScoreCircle';

import styles from './ScoreSummary.module.scss';

class ScoreSummary extends React.Component {

  renderSubjects () {

    const mathColor = '#963484';
    const elaColor = '#134f8d';
    const readingColor = '#f58b1e'
    const scienceColor = '#00a2a7'
  
    const { testID, sections } = this.props;

    const range = (testID === 'SAT') ? 800 : 36;
    if (testID === 'SAT') {
      const { math, ela } = sections;
      return (
        <React.Fragment>
          <ScoreCircle
            score={math ? math.score : undefined}
            range={range}
            chartTitle={'Math'}
            scoreColor={mathColor} />
          <ScoreCircle
            score={ela ? ela.score : undefined}
            range={range}
            chartTitle={'ELA'}
            scoreColor={elaColor} />
        </React.Fragment>
      );
    }
    else {
      const {math, reading, english, science } = sections;
      return (
        <React.Fragment>
          <div className={styles.subjectScore}>
            <ScoreCircle
              score={english ? english.score : undefined}
              range={range}
              chartTitle={'English'}
              scoreColor={elaColor}></ScoreCircle>
          </div>
          <div className={styles.subjectScore}>
            <ScoreCircle
              score={math ? math.score : undefined}
              range={range}
              chartTitle={'Math'}
              scoreColor={mathColor}></ScoreCircle>
          </div>
          <div className={styles.subjectScore}>
            <ScoreCircle
              score={reading ? reading.score : undefined}
              range={range}
              chartTitle={'Reading'}
              scoreColor={readingColor}>
              </ScoreCircle>
          </div>
          <div className={styles.subjectScore}>
            <ScoreCircle
              score={science ? science.score : undefined}
              range={range}
              chartTitle={'Science'}
              scoreColor={scienceColor}></ScoreCircle>
          </div>
        </React.Fragment>
      );
    }
  }

  render () {
    const compositeColor = '#3B3561'
    // const compositeColor = '#00a2a7'
    const { testID, compositeScore } = this.props;
    const range = (testID === 'SAT') ? 1600: 36;
    
    return (
      <div className={styles.scoreSummary}>
        <div className={styles.composite}>
          <ScoreCircle
            score={compositeScore}
            range={range}
            chartTitle={'Composite'}
            scoreColor={compositeColor}></ScoreCircle>
        </div>
        <div className={styles.sections}>
          {this.renderSubjects()}
        </div>
      </div>
    );
  }
}

ScoreSummary.propTypes = {
  testID: PropTypes.string.isRequired,
  sections: PropTypes.object.isRequired,
  compositeScore: PropTypes.number,
};

export default ScoreSummary;