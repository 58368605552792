import React from 'react';
import PropTypes from 'prop-types';

import styles from './ReportTable.module.scss';

class ReportTable extends React.Component {
  
  tableRow(row) {  
    const tableRow = row.map((value, index) => 
      <td key={index}>{value}</td>);
    return tableRow;
  }

  tableRows(rows) {
    const tableRows = rows.map((row, rIndex) => {
      return (
        <tr className={styles.tableRow} key={rIndex}>
          {this.tableRow(row)}
        </tr>
      )
    });
    return tableRows;
  }
  tableHeader(headers) {  
    const tableHeader = headers.map((value, index) => 
      <th key={index}>{value}</th>);
    return tableHeader;
  }

  render() {
    const { headers, data } = this.props;
    return (
      <table className={styles.reportTable}>
        <thead className={styles.tableHeader}>
          <tr>{this.tableHeader(headers)}</tr>
        </thead>
        <tbody>
          {this.tableRows(data)}
        </tbody>
      </table>
    );
  }
}

ReportTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default ReportTable;