import React, { useEffect, useState }  from 'react';
import { useSelector } from 'react-redux'
import { useParams} from 'react-router';
import HomeLayoutContainer from '../HomeLayoutContainer';
import StudentList from '../StudentList';
import StudentDetails from '../StudentDetails';

// import styles from './StudentHome.module.scss';

function StudentHome() {
  const [selectedStudent, setSelectedStudent] = useState();
  const program = useSelector(state=>state.program);
  const { id } = useParams();

  useEffect(() => {
    const student = program.students.find(x => x.UUID===id);
    setSelectedStudent(student);
  }, [id, program]);

  return (
    <HomeLayoutContainer 
      rightSideContent=
      { (selectedStudent)
        ? <StudentDetails 
          student={selectedStudent}
          program={program} />
        : <StudentList/>
      }
      showTopNavBar={!selectedStudent}/>
  );
}

export default StudentHome;