import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../redux/actions';
import { addDays, setHours, setMinutes, parseISO } from 'date-fns';
import Selector from '../../Selector';
import SelectorPracticeTests from '../../SelectorPracticeTests';
import DateTimePicker from 'react-datetime-picker';
import ButtonLinkTo from '../../ButtonLinkTo';
import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';
import SuccessMessage from '../../SuccessMessage';
import WaitForIt from '../../WaitForIt';
import styles from './PracticeTestEventAddEdit.module.scss';

function PracticeTestEventAddEdit(props) {
  const { scheduledPracticeTest } = props;
  const mode = scheduledPracticeTest ? 'EDIT' : 'ADDNEW';
  const userUUID = useSelector(state=>state.userUUID);
  const programs = useSelector(state=>state.programs);
  const selectedProgram = useSelector(state=>state.program);
  const posting = useSelector(state=>state.posting);
  const error = useSelector(state=>state.error);
  const success = useSelector(state=>state.success);
  const dispatch = useDispatch();

  var tomorrow = addDays(new Date(), 1);
  tomorrow = setHours(tomorrow, 10);
  tomorrow = setMinutes(tomorrow, 0);

  const [testDate, setTestDate] = useState(scheduledPracticeTest ? parseISO(scheduledPracticeTest.date) : tomorrow);
  const [programID, setProgramID] = useState(scheduledPracticeTest ? scheduledPracticeTest.programID : selectedProgram.ID);
  const [practiceTest, setPracticeTest] = useState(scheduledPracticeTest ? scheduledPracticeTest.practiceTest : 'SATTest1');
  const [submitPressed, setSubmitPressed] = useState(false);

  const saveEvent = () => {
    setSubmitPressed(true);
    if (mode==='ADDNEW')
      dispatch(allActions.scheduledPracticeTestAdd(userUUID, programID, testDate, practiceTest));
    else
      dispatch(allActions.scheduledPracticeTestUpdate(userUUID, scheduledPracticeTest.ID, testDate, practiceTest));
  }

  const deleteEvent = () => {
    if (window.confirm('Are you sure you wish to cancel this test?')) {
      setSubmitPressed(true);
      dispatch(allActions.scheduledPracticeTestDelete(userUUID, scheduledPracticeTest.ID));
    }
  }

  const errorDisplay = (submitPressed && error) 
    ? <React.Fragment>
        <div className={styles.header}>There was a problem saving your changes</div>
        <ErrorMessage errors={error} />
      </React.Fragment>
    : null;

  const successfulSaveDisplay = (submitPressed && success)
    ? <SuccessMessage message={'all saved!'} />
    : null;

  if (posting) {
    return (
      <div className={styles.practiceTestEventAddEdit}>
        <div className={styles.title}>Schedule a Practice Test</div>
        <WaitForIt waitingMessage='saving your changes'></WaitForIt>
      </div>
    );
  }

  const programOptions = programs.map((value, index) => 
    <option key={index} value={value.ID}>{value.name}</option>);

  const programDisplay = (mode === 'ADDNEW') 
    ? <Selector 
        onChange={(selected) => setProgramID(selected)} 
        label='Class' 
        options={programOptions}
        selectedValue={programID} />
    : <React.Fragment>
        <div className={styles.label}>Class:</div>
        <div>{scheduledPracticeTest.programName}</div>
      </React.Fragment>;

  return (
    <div className={styles.practiceTestEventAddEdit}>
      <div className={styles.title}>Schedule a Practice Test</div>
      <div className={styles.form}>
        <div className={styles.row}>
          {programDisplay}
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Test Date:</div>
          <DateTimePicker 
            onChange={(date) => setTestDate(date)}
            value={testDate}/>
        </div>
        <div className={styles.row}>
          <SelectorPracticeTests 
            onChange={(selected) => setPracticeTest(selected)}
            selectedValue={practiceTest} />
        </div>
      </div>
      <div>Students will be sent their Test Link emails at 5pm on the day before the test.</div>
      { successfulSaveDisplay 
        ? <div className={styles.postResponse}>{successfulSaveDisplay}</div>
        : null
      }
      <div className={styles.buttonRow}>
        { successfulSaveDisplay
          ? null
          : <Button onClick={saveEvent} ctaHighlight={true} smallButton={true}>save</Button>
        }
        <ButtonLinkTo linkTo='/practice-test-admin/' text='close' smallButton={true}></ButtonLinkTo>

      </div>
      { mode==='ADDNEW' 
        ? null
        :
          <div className={styles.buttonRow}>          
            <Button onClick={deleteEvent} linkButton={true}>cancel scheduled test</Button>
          </div>
      }

      <div className={styles.postResponse}>
        {errorDisplay}
      </div>
    </div>
  );
}

PracticeTestEventAddEdit.propTypes = {
  scheduledPracticeTest: PropTypes.object
};

export default PracticeTestEventAddEdit;