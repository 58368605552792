import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from './SideNavBarItem.module.scss';

function SideNavBarItem(props) {

  const { text, linkTo, onClick } = props;

  return (
    linkTo 
    ? 
      <NavLink 
      className={styles.sideNavBarItem}
      activeClassName={styles.selectedItem}
      to={linkTo}>{text}</NavLink>
    : <div className={styles.sideNavBarItem} onClick={onClick}>{text}</div>
  );
}


SideNavBarItem.propTypes = {
  text: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  onClick: PropTypes.func
};

export default SideNavBarItem;