import React from 'react';
import PropTypes from 'prop-types';
import Selector from '../Selector';

// import styles from './SelectorPracticeTests.module.scss';

const PracticeTests = [
  <option key={1} value={'SATTest1'}>{'SATTest1'}</option>,
  <option key={2} value={'SATTest2'}>{'SATTest2'}</option>,
  <option key={3} value={'SATTest3'}>{'SATTest3'}</option>,
  <option key={4} value={'SATTest4'}>{'SATTest4'}</option>,
  <option key={5} value={'SATTest5'}>{'SATTest5'}</option>,
  <option key={6} value={'SATTest6'}>{'SATTest6'}</option>,
  <option key={7} value={'SATTest7'}>{'SATTest7'}</option>,
  <option key={8} value={'SATTest8'}>{'SATTest8'}</option>,
  <option key={9} value={'ACTRedbookTest2'}>{'ACTRedbookTest2'}</option>,
  <option key={10} value={'ACTRedbookTest3'}>{'ACTRedbookTest3'}</option>,
  <option key={11} value={'ACTRedbookTest4'}>{'ACTRedbookTest4'}</option>,
  <option key={12} value={'ACTRedbookTest5'}>{'ACTRedbookTest5'}</option>,
  <option key={13} value={'ACTPrepGuideTest1'}>{'ACTPrepGuideTest1'}</option>,
  <option key={14} value={'ACTPrepGuideTest2'}>{'ACTPrepGuideTest2'}</option>,
  <option key={15} value={'ACTPrepGuideTest3'}>{'ACTPrepGuideTest3'}</option>];

function SelectorPracticeTests(props) {
  const tests = PracticeTests;
  const { selectedValue, onChange } = props;

  return (
    <Selector 
      onChange={(e) => onChange(e)} 
      label='Test' 
      options={tests} 
      selectedValue={selectedValue} />
  );
}

SelectorPracticeTests.propTypes = {
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
};

export default SelectorPracticeTests;