import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams} from 'react-router';
import allActions from '../../../redux/actions';
import { format, parseISO } from 'date-fns';

import WaitForIt from '../../WaitForIt';
import ErrorMessage from '../../ErrorMessage';
// import Images from './../../../assets/Images';
import styles from './StudentReport.module.scss';

function StudentReport() {
  const error = useSelector(state=>state.error);
  const reportData = useSelector(state=>state.reportData);
  const loading = useSelector(state=>state.loading);
  const { uuid, student, practicetest } = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(allActions.getStudentPracticeTestReport(uuid, student, practicetest));
  }, [dispatch, uuid, student, practicetest]);

  // student-report/39d2e96877a14e929f1f51f12d7fd801/4a880d7c9eef4f04880da862cb7323bd/SATTest6
  return (
    <div className={styles.StudentReport}>
      <div className={styles.pageTitle}>Student Report</div>
      { loading ? 
        <div className={styles.reportLoading}>
          <WaitForIt waitingMessage='Running Report'></WaitForIt>
        </div>
        : null
      }
      { error ? 
        <div className={styles.reportLoadError}>
          <div className={styles.header}>There was a problem loading your report</div>
            <ErrorMessage errors={error} />
        </div>
        :null
      }
      <div> we did it!</div>
    </div>
  );
}

export default StudentReport;