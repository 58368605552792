import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import StudentReport from './components/Reports/StudentReport';
import PracticeTestHome from './components/PracticeTestHome';
import ReportHome from './components/ReportHome';
import EnsureLoggedIn from './components/EnsureLoggedIn';
import VerifyUser from './components/VerifyUser';
import Home from './components/Home';
import StudentHome from './components/StudentHome';
import LostPassword from './components/LostPassword';
import ProgramAdminHome from './components/AdminScreens';

import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.appMain}>   
      <BrowserRouter>
        <Switch>
       
          <Route exact path ='/home' component={EnsureLoggedIn(Home)} />

          {/* <Route path='/admin' component={() => { 
            // change this to use login url
            window.location.href = 'https://app.testive.com/program-admin/'; 
            return null;
          }}/> */}
          <Route exact path ='/reports' component={EnsureLoggedIn(ReportHome)} />
          <Route exact path ='/student-report/:uuid/:student/:practicetest' component={(StudentReport)} />
          <Route exact path ='/practice-test-admin/:mode?/:id?' component={EnsureLoggedIn(PracticeTestHome)} />
          <Route exact path ='/lostpassword' component={LostPassword} />
          <Route exact path ='/admin' component={EnsureLoggedIn(ProgramAdminHome)} />
          <Route exact path ='/students/:id?' component={EnsureLoggedIn(StudentHome)} />
          <Route exact path ='/login' component={VerifyUser} /> 
          <Route exact path ='/' component={VerifyUser} /> 
          <Route exact path ='/:uuid/' component={VerifyUser} /> 
          <Route exact path ='/:uuid' component={VerifyUser} /> 
          
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
