import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

import styles from './PracticeTestHistoryChart.module.scss';

function PracticeTestHistoryChart(props) {
  const { practiceTestHistory, isACT } = props;
  const yAxis = isACT ? [0,36] : [400, 1600];
  const yAxisInterval = isACT ? 36 : 100;

  console.log(isACT, yAxis);
  return (
    <div className={styles.practiceTestHistoryChart}>
      <LineChart
        width={350}
        height={200}
      >
        <Tooltip />
        <XAxis 
          dataKey='test' 
          type='category'
          allowDuplicatedCategory={false} 
          padding={{ left: 20, right: 20 }} 
          height={60} />
        <YAxis domain={yAxis} padding={{ left: 200 }}></YAxis>
        {practiceTestHistory.map(s => (
          <Line dataKey="score" data={s.data} name={s.name} key={s.name} />
        ))}
        {/* <Line type='monotone' dataKey='composite' stroke="#F5AB00" /> */}

        {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}

        {/* <CartesianGrid stroke="#eee" strokeDasharray="5 0"/> */}
      </LineChart>
    </div>
  );
  
}

PracticeTestHistoryChart.propTypes = {
  practiceTestHistory: PropTypes.array.isRequired,
  isACT: PropTypes.bool.isRequired,
};

export default PracticeTestHistoryChart;
