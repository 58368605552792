import React from 'react';
import PropTypes from 'prop-types';

import styles from './Selector.module.scss';

class Selector extends React.Component {

  render() {
    const { options, label, onChange, selectedValue } = this.props;
    // options.unshift(<option selected disabled hidden>Choose one</option>);
    return (
      <div className={styles.selector}>
        <div className={styles.label}>{label}:</div>
        <select
          value={selectedValue}
          onChange={(e) => onChange(e.target.value)}>
          {options}
        </select>
      </div>
    );
  }
}

Selector.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  selectedValue: PropTypes.any,
  showDisabledDefault: PropTypes.bool.isRequired
};

Selector.defaultProps = {
  showDisabledDefault: false
};

export default Selector;