import React from 'react';
import PropTypes from 'prop-types';

import styles from './StudentMetricsBar.module.scss';

function ProgramLengthMetric(props) {
  return (
    <div className={styles.metric}>
      <div className={styles.title}>Time in Program</div>
      <div className={styles.content}>
        <div className={styles.singleMetic}>{props.value}</div>
        <div className={styles.footnote}>days</div>
      </div>
    </div>
  );
}

function TimeMetric(props) {

  const allTime = formatTime(props.total);
  const recentTime = formatTime(props.recent);

  return (
    <div className={styles.metric}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.content}>
        <div className={styles.metricPair}>
          <div className={styles.questionMetric}>{recentTime}<span>last 7 days</span></div>
          <div className={styles.questionMetric}>{allTime}<span>all time</span></div>
        </div>
      </div>
    </div>
  );
}

function ReviewMetric(props) {
  const { reviewCount, reviewTotal } = props;
  const percentReviewed = (reviewTotal > 0) ? Math.round((reviewCount/reviewTotal)*100) : undefined;

  return (
    <div className={styles.metric}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.content}>
        { (percentReviewed)
        ? 
          <React.Fragment>
            <div className={styles.singleMetic}>{percentReviewed}%</div> 
            <div className={styles.footnote}>{reviewCount} of {reviewTotal}</div>
          </React.Fragment>
        : <div className={styles.noData}>no data</div> }

      </div>
    </div>
  );
}

function QuestionMetric(props) {
  return (
    <div className={styles.metric}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.content}>
        <div className={styles.metricPair}>
          <div className={styles.questionMetric}>{props.recent} <span>last 7 days</span></div>
          <div className={styles.questionMetric}>{props.total} <span>all time</span></div>
        </div>
      </div>
    </div>
  );
}

function ScoreMetric(props) {
  const { baselineScore, currentScore, superScore, latestScore } = props;
  
  return (
    <div className={styles.metric}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.scoresContent}>
        <div className={styles.scores}>
          <div className={styles.score}>{baselineScore.composite ? baselineScore.composite : '?'}</div>
          <div className={styles.score}>{latestScore.composite ? latestScore.composite : '?'}</div>
          <div className={styles.score}>{superScore.composite ? superScore.composite : '?'}</div>
          {/* <div className={styles.score}>{currentScore.composite ? currentScore.composite : '?'}</div> */}
        </div>
        <div className={styles.labels}>
          <div className={styles.label}>baseline score</div>
          <div className={styles.label}>latest score</div>
          <div className={styles.label}>super score</div>
          {/* <div className={styles.label}>current score</div> */}
        </div>
      </div>
    </div>
  );
}

function formatTime(duration) {

  const display_seconds = duration % 60;
  const minutes= Math.floor((duration / 1000) % 60);
  const hours = Math.floor((duration / (1000 * 60)) % 60);

return ( 
  <div className={styles.timeMetric}>
    { (hours > 0) 
      ? <span className={styles.timeValue}>{hours}<span className={styles.timeUnit}>h</span></span>
      : null
    }
    <span className={styles.timeValue}>{minutes}<span className={styles.timeUnit}>m</span></span> 
    <span className={styles.timeValue}>{display_seconds}<span className={styles.timeUnit}>s</span></span> 
  </div>);
};

function StudentMetricsBar(props) {
  const { 
    daysInProgram,
    timeTotal,
    timeRecent,
    questionCountTotal,
    questionCountRecent,
    reviewCount,
    reviewTotal,
    scores} = props.studentDetails;

  return (
    <div className={styles.studentMetricsBar}>
      <ProgramLengthMetric 
        title='Weeks in Program'
        value={daysInProgram}/>
      <TimeMetric 
        title='Time on Site'
        total={timeTotal}
        recent={timeRecent}/>
      <QuestionMetric 
        title='Questions'
        total={questionCountTotal}
        recent={questionCountRecent}/>
      <ReviewMetric
        title='Reviewed' 
        reviewCount={reviewCount}
        reviewTotal={reviewTotal} />
      <ScoreMetric 
        title='Scores'
        baselineScore={scores.baseline}
        latestScore={scores.latest}
        currentScore={scores.current}
        superScore={scores.super}/>
    </div>
  );
}

StudentMetricsBar.propTypes = {
  studentDetails: PropTypes.object.isRequired,
};

export default StudentMetricsBar;