import React from 'react';
import PropTypes from 'prop-types';
import SideNavBar from '../SideNavBar';
import ProgramSelector from '../ProgramSelector'
import styles from './HomeLayoutContainer.module.scss';

function HomeLayoutContainer(props) {

  const { rightSideContent, showTopNavBar, titleBarText } = props;

  const titleBar = (titleBarText)
    ? <div className={styles.titleBar}>{titleBarText}</div>
    : null; 

  const topNav = (showTopNavBar) 
    ? <div className={styles.topNav}><ProgramSelector/></div>
    : null;

  return (
    <div className={styles.homeLayoutContainer}>
      <div className={styles.leftSideNav}>
        <SideNavBar />
      </div>
      <div className={styles.rightSideContent}>
        {topNav}
        {titleBar}
        {rightSideContent}
      </div>
    </div>
  );
}

HomeLayoutContainer.propTypes = {
  rightSideContent: PropTypes.node.isRequired,
  showTopNavBar: PropTypes.bool.isRequired,
  titleBarText: PropTypes.string
};

HomeLayoutContainer.defaultProps = {
  showTopNavBar: true
}

export default HomeLayoutContainer;