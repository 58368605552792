import React from 'react';
import styles from './AppTitle.module.scss';
import Images from './../../assets/Images';

function AppTitle() {
  return (
    <div className={styles.appTitle}>
      <img src={Images.appTitleLogo} alt='LOGO'></img>
    </div>
  );
}

export default (AppTitle);
