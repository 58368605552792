import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import styles from './ScoreCircle.module.scss';

class ScoreCircle extends React.Component {


  render () {
    const { score, range, chartTitle, scoreColor } = this.props;

    var i = 1;
    var increment = (range===36) ? 1 : (range===1600) ? 40 : 20;
    var len = range/increment;
    
    var data = [];

    for (; i <= len; i++) {
      data.push({ name: i.toString(), value: 1*increment});
    }
    const scoreIndex = score ? score/increment : 0;
    const scoreDisplay = score ? score: '?';
    return (
      <div className={styles.scoreCircle}>
        <div className={styles.chartTitle}>{chartTitle}</div>
        <div className={styles.chartContainer}>
        <ResponsiveContainer>
            <PieChart>
              <Pie
                data={data}
                dataKey='value'
                cx="50%"
                cy="50%"
                innerRadius="70%"
                outerRadius="100%"
                stroke="none"
                paddingAngle={2}
                startAngle={200}
                endAngle={-20}
                >
              {
                data.map((entry, index) => 
                  <Cell 
                    key={index} 
                    fill={(index<=scoreIndex) ? scoreColor: styles.remaining} 
                    fillOpacity={(index<=scoreIndex) ? 1: .25}
                    />)
              }
            </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div className={styles.score}>
          <div className={styles.scoreValue}>{scoreDisplay}</div>
            <div className={styles.scoreOutOf}>of {range}</div>
          </div>
        </div>
      </div>
    );
  }
}

ScoreCircle.propTypes = {
  score: PropTypes.number,
  range: PropTypes.number.isRequired,
  chartTitle: PropTypes.string,
  scoreColor: PropTypes.string,
};


export default ScoreCircle;
