import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../redux/actions';

import DashboardCardTimeOnSite from '../DashboardCardTimeOnSite';
import DashboardCardReview from '../DashboardCardReview';
import DashboardCardQuestions from '../DashboardCardQuestions';
import DashboardCardNextPracticeTest from '../DashboardCardNextPracticeTest';
import DashboardCardPracticeTestHistory from '../DashboardCardPracticeTestHistory';
import WaitForIt from '../WaitForIt';
import ErrorMessage from '../ErrorMessage';
import styles from './Dashboard.module.scss';


function Dashboard() {
  const userUUID = useSelector(state=>state.userUUID);
  const program = useSelector(state=>state.program);
  const error = useSelector(state=>state.error);
  const appData = useSelector(state=>state.appData);
  const loading = useSelector(state=>state.loading);
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(allActions.getDashboard(userUUID, program.ID));
  }, [userUUID, program]);

  if (loading)
    return <WaitForIt waitingMessage={'loading analytics for ' + program.name} />;

  if (error)
    return <ErrorMessage errors={error} />;

  if (appData) {
    return (
      <div className={styles.dashboard}>
        <div className={styles.cards}>
          <DashboardCardTimeOnSite  
              total={appData.time.total}
              recent={appData.time.recent} />
          <DashboardCardQuestions 
              total={appData.questions.total}
              recent={appData.questions.recent} />
          <DashboardCardReview  
            reviewCount={appData.review.reviewed}
            reviewTotal={appData.review.reviewable}/>
          <DashboardCardNextPracticeTest 
            nextTestName={appData.nextPracticeTest ? appData.nextPracticeTest.testName: undefined}
            nextTestDate={appData.nextPracticeTest ? appData.nextPracticeTest.testDate: undefined}/>
          <DashboardCardPracticeTestHistory 
            isACT={program.testType==='ACT'}
            practiceTestHistory={appData.practiceTestHistory} />
        </div>
      </div>
    );
  }
  return null;
}

export default Dashboard;