import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import allActions from '../../../redux/actions';
import ErrorMessage from '../../ErrorMessage';
import SuccessMessage from '../../SuccessMessage';
import styles from'./ProgramSupporterAdmin.module.scss';

function ProgramSupporterAdmin(props) {
  const { onClose, userUUID, program } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  
  const error = useSelector(state=>state.error);
  const programSupporterSuccess = useSelector(state => state.programSupporterSuccess);
  
  useEffect(() => {
    dispatch(allActions.clearErrorSuccess());
  }, []);

  useEffect(() => {
    if(programSupporterSuccess === true) {
      dispatch(allActions.getSelectedProgram(userUUID, program.ID))
    }
  },[programSupporterSuccess])

  const errorDisplay = (error) 
  ? <React.Fragment>
      <div className={styles.header}>There was a problem saving your changes</div>
      <ErrorMessage errors={error} />
    </React.Fragment>
  : null;

  const successfulSaveDisplay = (programSupporterSuccess)
    ? <SuccessMessage message={'all saved!'} />
    : null;

  const saveUser = e => {
    e.preventDefault();
    dispatch(allActions.programSupporterAdd(userUUID, program.ID, firstName, lastName, email));
  };

  return (
    <div className={styles.programSupporterAdmin}>
      <div className={styles.title}>Add New Supporter</div>
      <form className={styles.form} onSubmit={saveUser}>
        <div className={styles.row}>
          <input type="text" placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} required/>
        </div>
        <div className={styles.row}>
          <input type="text"  placeholder='First Name' value={firstName} onChange={e => setFirstName(e.target.value)} required/>
        </div>
        <div className={styles.row}>
          <input type="text" placeholder='Last Name' value={lastName} onChange={e => setLastName(e.target.value)} required/>
        </div>
        <div className={styles.buttonRow}>
          <button type='submit'>save</button>
          <button onClick={onClose}>close</button>
        </div>
      </form>
      <div className={styles.postResponse}>
        {successfulSaveDisplay}
        {errorDisplay}
      </div>
    </div>
  );
}

export default ProgramSupporterAdmin;