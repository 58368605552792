import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { format, parseISO } from 'date-fns';
import { CSVLink } from 'react-csv';
import Selector from '../../Selector';
import allActions from '../../../redux/actions';

import styles from './PracticeTestEvents.module.scss';
import ButtonLinkTo  from '../../ButtonLinkTo';


function PracticeTestEventRow(props) {
  const { testEvent, admin } = props;
  const editLink = '/practice-test-admin/edit/'+testEvent.ID;
  const detailsLink = '/practice-test-admin/view/'+testEvent.ID;
  const dispatch = useDispatch();
  const userUUID = useSelector(state=>state.userUUID);

  return (
    <tr>
      <td>{testEvent.programName}</td>
      <td>{format(parseISO(testEvent.date), 'EEEE, MMMM do h:mm a')}</td>
      <td>{testEvent.practiceTest}</td>
      <td>{testEvent.status}</td>
      <td>
        { (testEvent.status === 'SCHEDULED' && admin === true) 
          ?  
            <div className={styles.button}>
              <ButtonLinkTo linkTo={editLink} text='edit' />      
            </div>
          : null
        }
        <div className={styles.button} onClick={ () => {dispatch(allActions.getSelectedProgram(userUUID, testEvent.program.ID)) }} >
          <ButtonLinkTo linkTo={detailsLink} text='view roster' />  
        </div> 
      </td>
    </tr>
  );
}

PracticeTestEventRow.propTypes = {
  testEvent: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
};

function PracticeTestEvents(props) {
  const { testEvents, programs, admin } = props;
  const [programFilter, setProgramFilter] = useState(-1);
  const [programFilterOptions, setProgramFilterOptions] = useState([]);
  const [dateFilter, setDateFilter] = useState();
  const [practiceTestEvents, setPracticeTestEvents] = useState([]);

  useEffect(() => {
    if (programs) {
      let programOptions = programs.map((value, index) => (
        <option key={index} value={value.ID}>{value.name}</option>
      ));      
      programOptions.unshift(<option key={-1} value={-1}>all programs</option>);
      setProgramFilterOptions(programOptions);
    }
  }, [programs]);

  useEffect(() => {
    console.log('use effect ', dateFilter, programFilter, testEvents);
    if (testEvents) {
      var results = testEvents;
      if (programFilter>-1)
        results = testEvents.filter((event) => (event.programID==programFilter));
      if (dateFilter)
      {
        const dFilter = format(dateFilter, 'MM/d/yy');
        results = results.filter((event) => (format(parseISO(event.date), 'MM/d/yy')===dFilter));
      }

      setPracticeTestEvents(results.map((testEvent, index) => (
        <PracticeTestEventRow
          key={index} 
          admin={admin}
          testEvent={testEvent}>
        </PracticeTestEventRow>    
      )));
    }      
  }, [testEvents, admin, programFilter, dateFilter]);

  return (
    <div className={styles.practiceTestEvents}>
      <div className={styles.filterContainer}>
        <div className={styles.filterOption}>
        <Selector 
          onChange={(selected) => setProgramFilter(selected)} 
          label='Class' 
          options={programFilterOptions}
          selectedValue={programFilter}>
        </Selector>
       </div>
        <div className={styles.filterOption}> 
        <div className={styles.label}>Date:</div>
        <DatePicker 
          onChange={(date) => setDateFilter(date)}
          value={dateFilter}/>
      </div>
      </div>
      <table className={styles.table}>
        <thead className={styles.tableHeader}>
          <tr>
            <th>Class</th>
            <th>Test Date</th>
            <th>Test</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {practiceTestEvents}
        </tbody>
      </table>
    </div>
  );
}

PracticeTestEvents.propTypes = {
  testEvents: PropTypes.array.isRequired,
  programs: PropTypes.array.isRequired,
  admin: PropTypes.bool.isRequired,
};

export default PracticeTestEvents;
