const images = {
	error: require('./images/error.svg'),
	success: require('./images/thumbsup.svg'),
	downArrow: require('./images/downArrow.svg'),
	calendar: require('./images/calendar.svg'),
	export: require('./images/export.svg'),
	appTitleLogo: require('./images/alist-logo.png'),
	checkMark: require('./images/check.svg'),
	flagOrange: require('./images/flagOrange.svg'),
	popout: require('./images/popout.svg'),
	back: require('./images/backArrow.svg'),
};

export default images;