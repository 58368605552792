import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../redux/actions';
import HomeLayoutContainer from '../../HomeLayoutContainer';
import CSVReader from 'react-csv-reader'
import Button from '../../Button';
import ProgramStudentAdmin from '../ProgramStudentAdmin';
import ProgramSupporterAdmin from '../ProgramSupporterAdmin';
import WaitForIt from '../../WaitForIt';
import styles from './ProgramAdminHome.module.scss';
import ErrorMessage from '../../ErrorMessage';
import SuccessMessage from '../../SuccessMessage';

function ProgramSupporters() {
  const program = useSelector(state=>state.program);
  const userUUID = useSelector(state=>state.userUUID);
  const [showAddEdit, setShowAddEdit] = useState(false);
  
  const tableRows = program.supporters.map((s, index) => {
    return (
      <tr key={index} >
        <td>{s.email}</td>
        <td>{s.firstName}</td>
        <td>{s.lastName}</td>
      </tr>     
      );}
  );
  const table = 
    <table>
      <thead>
        <tr>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>;

  return (
    <div className={styles.container}>
      <div className={styles.titleRow}>  
        <div className={styles.title}>
          Program Supporters
           <Button onClick={() => setShowAddEdit(!showAddEdit)} linkButton={true}>{showAddEdit ? 'close': 'add user'}</Button>
        </div>
      </div>
      <div>
        { showAddEdit 
          ? <ProgramSupporterAdmin 
              program={program} 
              userUUID={userUUID}
              onClose={() => setShowAddEdit(false)}/>
          : table
        }
      </div>
    </div>
  );
}

function ProgramStudents({forceShowImport}) {
  const program = useSelector(state=>state.program);
  const userUUID = useSelector(state=>state.userUUID);
  const dispatch = useDispatch();
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [showImport, setShowImport] = useState(Boolean(forceShowImport));

  const tableRows = program.students.map((s, index) => {
    return (
      <tr key={index} >
        <td>{s.email}</td>
        <td>{s.firstName}</td>
        <td>{s.lastName}</td>
      </tr>     
      );}
  );

  const table = 
    <table>
      <thead>
        <tr>
          <th>Student Email</th>
          <th>Student First Name</th>
          <th>Student Last Name</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>;

  const toggleShowImportDialog = () => {
    dispatch(allActions.clearErrorSuccess());
    setShowAddEdit(false)
    setShowImport(!showImport);
  }

  const toggleAddDialog = () => {
    setShowImport(false);
    setShowAddEdit(!showAddEdit)
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleRow}>  
        <div className={styles.title}>Program Students
          <Button onClick={() => toggleShowImportDialog()} linkButton={true}>{showImport ? 'close': 'import users'}</Button>
          <Button onClick={() => toggleAddDialog()} linkButton={true}>{showAddEdit ? 'close': 'add user'}</Button>
        </div>
        { showImport ? <ImportStudentRoster/> : null }
      </div>
      <div>
        { showAddEdit 
          ? <ProgramStudentAdmin 
              program={program} 
              userUUID={userUUID}
              onClose={() => setShowAddEdit(false)}/>
          : table
        }
      </div>
    </div>
  );
}

function ImportStudentRoster() {
  const [fileData, setFileData] = useState();
  const program = useSelector(state=>state.program);
  const posting = useSelector(state=>state.posting);
  const error = useSelector(state=>state.error)
  const success = useSelector(state=>state.success)
  const userUUID = useSelector(state=>state.userUUID);
  const importStudentSuccess = useSelector(state=>state.importStudentSuccess);
  const dispatch = useDispatch();

  useEffect(() => {
    if(importStudentSuccess=== true) {
      dispatch(allActions.getSelectedProgram(userUUID, program.ID))
    }
  },[importStudentSuccess])

  const handleFileLoaded = (data, fileInfo) => {
    console.dir(data, fileInfo);
    setFileData(data);
  }
  const postFileData = (e) => {
    e.preventDefault();
    if (!posting) {
      dispatch(allActions.importStudentRoster(userUUID, program.ID, fileData));
    }
  }
  if (success) {
    return (
      <div className={styles.uploadContainer}>
        <SuccessMessage message='all set' />
      </div>
    );
  }
  if (error) {
    return (
      <div className={styles.uploadContainer}>
        <ErrorMessage errors={error}/>
      </div>
    );
  }
  return (
    <div className={styles.uploadContainer}>
      <div className={styles.instructions}>Import student roster (csv format):</div>
      <CSVReader 
        onFileLoaded={(data, fileInfo) => handleFileLoaded(data, fileInfo)}
        parserOptions={{
          header:true,
          skipEmptyLines: true,
          transformHeader: header => header.trim()
        }}
      />
      <button 
        onClick={(e)=> postFileData(e)} 
        disabled={!fileData}>import</button>
    </div>
  );
}

function ProgramAdmin() {
  const program = useSelector(state=>state.program);
  const posting = useSelector(state=>state.posting);
  const error = useSelector(state=>state.error)
  const success = useSelector(state=>state.success)
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(allActions.clearErrorSuccess());
  }, []);

  if (posting || !program)
    return <WaitForIt waitingMessage='processing your csv' />
  return (

    <div className={styles.programAdmin}>
      <ProgramSupporters /> 
      <ProgramStudents forceShowImport={error || success} />
    </div>);
}

function ProgramAdminHome() {
  return (
    <HomeLayoutContainer 
      rightSideContent={<ProgramAdmin/>}
      showTopNavBar={true}/>
  );
}

export default ProgramAdminHome;
