import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

import styles from './DashboardCardNextPracticeTest.module.scss';

function DashboardCardNextPracticeTest(props) {
  const { nextTestDate, nextTestName } = props;

  return (
    <div className={styles.dashboardCardNextPracticeTest}>
      <div className={styles.title}>Next Practice Test</div>
      <div className={styles.content}>
      { nextTestDate 
        ? <div className={styles.calendarContainer}> 
            <div className={styles.subTitle}>{nextTestName}</div>
            <div className={styles.calendar}>
              <div className={styles.month}>{format(parseISO(nextTestDate), 'MMMM')}</div>
              <div className={styles.date}>{format(parseISO(nextTestDate), 'd')}</div>
              <div className={styles.day}>{format(parseISO(nextTestDate), 'EEEE ha')}</div>
            </div>
          </div>
        : <div className={styles.subTitle}>Not scheduled</div>
      }
      </div>
    </div>
  );
}

DashboardCardNextPracticeTest.propTypes = {
  nextTestDate: PropTypes.string,
  nextTestName: PropTypes.string
};

export default DashboardCardNextPracticeTest;