import React from 'react';
import PropTypes from 'prop-types';

import { format, parseISO } from 'date-fns';
// import Checkbox from '../../Checkbox';
import KatexText from '../../KatexText';
import Images from '../../../assets/Images';
import styles from './QuestionLogItem.module.scss';

function QuestionLogItem(props) {
  const { 
    questionDate, 
    subject, 
    question, 
    questionId, 
    correct, 
    reviewedOn, 
    reviewNote } = props;
  const needsReview = !correct & !reviewedOn;

  const reviewBody = (reviewedOn) 
    ? <div className={styles.reviewNote}>
        <span className={styles.reviewedDate}>{format(parseISO(reviewedOn),'M/d/yy h:mma')}-  </span>
        {reviewNote}
      </div>
    : (needsReview)
      ? <div className={styles.missingReviewNote}>pending student review</div>
      : null;

  return (
    <div className={styles.questionLogItem}>
      <div className={styles.questionDate}>
        <div className='itemValue'>{format(parseISO(questionDate),'M/d/yy h:mma')}</div>
      </div>
      <div className={styles.subject}>
        <div className='itemValue'>{subject}</div>
      </div>
      <div className={styles.questionCell}>
        <KatexText text={question} />

        <div className={styles.gotoQuestionCell}>
          <a href={'https://app.testive.com/question-qa/'+questionId+'/?show_answer=true'} target='_blank' rel='noopener noreferrer'><img src={Images.popout} alt='view question'></img></a>
        </div>
      </div>
      <div className={styles.reviewCell}>{reviewBody}</div>
    </div>

  );
}


QuestionLogItem.propTypes = {
  questionId: PropTypes.number.isRequired,
  questionDate: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  correct: PropTypes.bool.isRequired,
  reviewedOn: PropTypes.string,
  reviewNote: PropTypes.string
};

export default QuestionLogItem;
