import React from 'react';
import PropTypes from 'prop-types';
import styles from './SuccessMessage.module.scss';
import Images from '../../assets/Images';

function SuccessMessage(props) {

  const { title, message } = props;

  if (!message) {
    return null;
  }
  else {
    return (
      <div className={styles.successMessage}>
        <img className={styles.icon} src={Images.success} alt='success'></img>
        <div className={styles.content}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.message}>
            {message}
          </div>
        </div>
      </div>
    );
  }
  
}

SuccessMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

SuccessMessage.defaultProps = {
  title: '',
  message: ''
};

export default SuccessMessage; 