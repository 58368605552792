import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { recoverLogin } from '../../redux/actions';
import AppTitle from './../AppTitle';


import styles from './LostPassword.module.scss';

class LostPassword extends React.Component {

  state = {
    email: '',
  }

  sendLink(event) {
    event.preventDefault();
    event.stopPropagation();

    this.props.recoverLogin(this.state.email)
  }
  
  render() {
    if(this.props.sentRecoveryEmail) {
      return (
        <div className={styles.verifyUser}>
          <div className={styles.mainContainer}>
            <AppTitle />
            <div className={styles.formContainer}>
              <div className={styles.info}>An email has been sent to {this.state.email} </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.verifyUser}>
        <div className={styles.mainContainer}>
          <AppTitle />
          <div className={styles.formContainer}>
            <div className={styles.info}>Enter your email to recover your login</div>
            <form onSubmit={this.sendLink.bind(this)}>
              <input 
                className={styles.textInput}
                name='email'
                type='email'
                autoComplete='email'
                onChange={(e) => this.setState({email: e.target.value})}
                placeholder='email'
                />
              <input className={styles.submitButton} type="submit" value='SEND MY LINK' />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

LostPassword.propTypes = {
  recoverLogin: PropTypes.func.isRequired,
  sentRecoveryEmail: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    sentRecoveryEmail: state.sentRecoveryEmail
  };
}

function mapDispatchToProps(dispatch) {
  return {
    recoverLogin: (email) => dispatch(recoverLogin(email))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LostPassword);