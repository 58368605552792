import React from 'react';
import PropTypes from 'prop-types';

import styles from './SelectOption.module.scss';

function SelectOption(props) {
  const { value, text, onClick } = props;
    return (
      <div className={styles.selectOption} onClick={(e) => onClick(value)}>{text}</div>
    );
}

SelectOption.propTypes = {
  value: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SelectOption;