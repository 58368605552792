import React, { useEffect }  from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../redux/actions';
import StudentActivityLog from '../StudentActivityLog';
import StudentScoreSummary from '../StudentScoreSummary';
import StudentMetricsBar from '../StudentMetricsBar';
import ErrorMessage from '../ErrorMessage';
import WaitForIt from '../WaitForIt';
import ButtonLinkTo from '../ButtonLinkTo';
import styles from './StudentDetails.module.scss';
// import Images from './../../assets/Images';

function StudentDetails(props) {
  const { student, program } = props;
  const loading = useSelector(state=>state.loading);
  const userUUID = useSelector(state=>state.userUUID);
  const error = useSelector(state=>state.error);
  const studentDetails = useSelector(state=>state.studentDetails);
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(allActions.getStudentDetails(userUUID, student.UUID, program.testType));
  }, [dispatch, userUUID, student, program]);

  let mainContent = null;
  if (error) {
    mainContent = <React.Fragment>
      <div className={styles.errorContainer}>
        <div className={styles.header}>There was a problem loading your student data</div>
        <ErrorMessage errors={this.props.error} />
      </div></React.Fragment>;
  }
  else if (loading || !studentDetails) {
    mainContent = <WaitForIt waitingMessage='Loading student data'></WaitForIt>;
  }
  else {
    mainContent = 
    <React.Fragment>
      <div className={styles.metrics}>
        <div className={styles.subTitle}>Engagement</div>
          <StudentMetricsBar studentDetails={studentDetails} />
        </div>
      <div className={styles.scores}>
        <div className={styles.subTitle}>Scores</div>
        <StudentScoreSummary 
          practiceTestHistory={studentDetails.practiceTestHistory} 
          scores={studentDetails.scores.super}
          testType={program.testType}/>
      </div>
      <div className={styles.activity}>
      <div className={styles.subTitle}>Activity</div>
        <StudentActivityLog 
          student={student}
          questions={studentDetails.questionLog}>
        </StudentActivityLog>
      </div>
    </React.Fragment>
  }

  return ( 
    <div className={styles.studentDetails}>
      <div className={styles.titleBar}>
        <div className={styles.title}>{student.displayName}</div>
        <ButtonLinkTo linkTo='/students' text='back to all students' smallButton={true}></ButtonLinkTo>
      </div>
      {mainContent}
    </div>
  );
}


StudentDetails.propTypes = {
  student: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
};

export default StudentDetails;
