import React from 'react';
import PropTypes from 'prop-types';

import PracticeTestHistoryChart from '../Charts/PracticeTestHistoryChart';

import styles from './DashboardCardPracticeTestHistory.module.scss';

function DashboardCardPracticeTestHistory(props) {
  const { practiceTestHistory, isACT} = props;

  return (
    <div className={styles.dashboardCardPracticeTestHistory}>
      <div className={styles.title}>Practice Test History</div>
      <div className={styles.content}>
        { practiceTestHistory.length > 0
          ? <div className={styles.chartContainer}>
              <PracticeTestHistoryChart 
                practiceTestHistory={practiceTestHistory}
                isACT={isACT}>
              </PracticeTestHistoryChart>
            </div>
          : <div className={styles.subTitle}>No Data</div>
        }
      </div>
    </div>
  );
}

DashboardCardPracticeTestHistory.propTypes = {
  practiceTestHistory: PropTypes.array.isRequired,
  isACT: PropTypes.bool.isRequired,
};

export default DashboardCardPracticeTestHistory;