import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './ButtonLinkTo.module.scss';

class ButtonLinkTo extends React.Component {

  render() {
    const { 
      text, 
      linkTo, 
      className, 
      ctaHighlight,
      disabled,
      transparent,
      smallButton,
      linkButton,
    } = this.props;

    let cx = classNames.bind(styles);
    let buttonStyle = cx({
      button: true,
      disabled: disabled,
      cta: ctaHighlight,
      transparent: transparent,
      small: smallButton,
      link: linkButton,
    });

    return (
      <NavLink 
        className={className !== undefined ? className : buttonStyle}
        activeClassName={styles.buttonStyle}
        to={linkTo}>{text}</NavLink>
    );
  }
}

ButtonLinkTo.propTypes = {
  text: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  smallButton: PropTypes.bool.isRequired,
  linkButton: PropTypes.bool.isRequired,
};

ButtonLinkTo.defaultProps = {
  ctaHighlight: true,
  disabled: false,
  transparent: false,
  smallButton: true,
  linkButton: false
};

export default ButtonLinkTo;