import { ACTION_TYPES } from './constants';

let email = localStorage.getItem('email');
let userUUID =  localStorage.getItem('userUUID');
let userName = localStorage.getItem('userName');
let admin = localStorage.getItem('admin');
let programs = JSON.parse(localStorage.getItem('programs'));
let program = JSON.parse(localStorage.getItem('program'));

let initialState = {
  loading: false,
  posting: false,
  error: undefined,
  success: undefined,
  programStudentSuccess: false,
  programSupporterSucces: false,
  importStudentSuccess: false,
  program: program ? program : undefined,
  programs: programs ? programs : undefined,
  email: email ? email : '',
  admin: admin ? admin === 'true' : false,
  userName: userName ? userName : '',
  userUUID: userUUID ? userUUID : undefined,
  isAuthenticating: false,
  isAuthenticated: userUUID ? true : false,
  login_url: undefined,
  appData: undefined,
  sentRecoveryEmail: false
}


function formatProgram(p) {
  return {
    name: p.display_name,
    ID: p.id,
    testType: (p.test_type == 2) ? 'ACT' : 'SAT',
    supporters: p.supporters ? p.supporters: [],
    students: formatStudentList(p.students),
    completedPracticeTests: p.completed_tests};
}


function formatPrograms(programList) {
  return programList.map(p => {
    return {
      name: p.display_name,
      ID: p.id,
    }
  });
}

function formatPracticeTests(practiceTests, programs) {
  var practiceTestFilters = [];
  practiceTestFilters = practiceTests.map(function(p) {
    return {
      ID: p.id,
      date: p.test_date_time,
      programName: p.program_name,
      programID: p.program_id,
      practiceTest: p.practice_test,
      status: p.status,
      program: programs.find(pg => pg.ID===p.program_id)
    };
  });
  return practiceTestFilters;
}

function formatStudentList(students) {
  const studentList = students.map(function(s) {
    return {
      // activeInLastWeek: s.active_in_last_week,
      baselineExists: s.baseline_exists,
      displayName: s.display_name,
      gradYear: s.grad_year,
      email: s.student_email,
      firstName: s.student_first_name,
      lastName: s.student_last_name,
      UUID: s.student_uuid,    
    };
  });
  return studentList;
}

function formatQuestionLog(questions) {
  return questions.map(function(q) {
    return {
      questionBody: q.question_body,
      questionDate: q.created_on_utc,
      questionId: q.question_id,
      correct: q.correct,
      isReviewed: q.is_reviewed,
      reviewedOn: q.reviewed_on_utc,
      reviewNote: q.comment,
      category: q.category
    };
  });
}

function formatPracticeTestHistory(history) {
  return history.map(function(pt) {
    return {
      composite: pt.composite,
      date: pt.date,
      name: pt.test_name,
      url: pt.url,
      sections: pt.sections
    };
  });
}

export function analyticsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.CLEAR_ERROR_SUCCESS:
      return Object.assign({}, state, {
        error: undefined,
        success: undefined
      });
            
    case ACTION_TYPES.GET_DASHBOARD_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: undefined,
        success: undefined,
        appData: undefined,
      });
      
    case ACTION_TYPES.GET_DASHBOARD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
        success: undefined,
        appData: undefined,
      });
      
    case ACTION_TYPES.GET_DASHBOARD_SUCCESS:
      
      return Object.assign({}, state, {
        loading: false,
        error: undefined,
        success: 'success',
        appData: action.data,
      });

    case ACTION_TYPES.USER_VERIFY_REQUEST:
      return Object.assign({}, state, {
        error: undefined,
        isAuthenticated: false,
        isAuthenticating: true,
      });

    case ACTION_TYPES.USER_VERIFY_SUCCESS:

      localStorage.setItem('userUUID', action.data.user_uuid);
      localStorage.setItem('userName', action.data.display_name);
      localStorage.setItem('email', action.data.email);
      localStorage.setItem('admin', action.data.admin);

      var programs = formatPrograms(action.data.programs);
  
      localStorage.setItem('programs', JSON.stringify(programs));
      var selectedProgram = programs[0];
      localStorage.setItem('program', JSON.stringify(selectedProgram));


      return Object.assign({}, state, {
        isAuthenticating: false,
        isAuthenticated: true,
        error: undefined,
        email: action.data.email,
        admin: action.data.admin,
        userUUID: action.data.user_uuid,
        userName: action.data.display_name,
        program: selectedProgram,
        programs: programs,
        login_url: action.data.login_url,
        loading: true
      });

    case ACTION_TYPES.USER_VERIFY_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isAuthenticated: false,
        isAuthenticating: false,
        programs: undefined,
      });
      
    case ACTION_TYPES.GET_SELECTED_PROGRAM_REQUEST:
      return Object.assign({}, state, {
        error: undefined,
        loading: true,
        reportData: undefined
      });

    case ACTION_TYPES.GET_SELECTED_PROGRAM_SUCCESS:
      console.log(action.data);
      selectedProgram = formatProgram(action.data)
      localStorage.setItem('program', JSON.stringify(selectedProgram));
      let index = state.programs.findIndex(p => p.ID === selectedProgram.ID);
      let programList = state.programs
      programList[index] = selectedProgram;

      return Object.assign({}, state, {
        loading: false,
        error: undefined,
        program: selectedProgram,
        programs: programList
      });

    case ACTION_TYPES.GET_SELECTED_PROGRAM_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        loading: false,
        reportData: undefined
      });

    case ACTION_TYPES.USER_LOGOUT:
      localStorage.clear();
      return initialState;

    case ACTION_TYPES.GET_REPORT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: undefined,
        reportData: undefined
      });
        
    case ACTION_TYPES.GET_REPORT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
        reportData: undefined
      });
        
    case ACTION_TYPES.GET_REPORT_SUCCESS:
      let report = action.data;
      report['reportHeader'] = action.reportHeader;
      return Object.assign({}, state, {
        loading: false,
        error: undefined,
        reportData: report
      });
  
    case ACTION_TYPES.SCHEDULE_TEST_GET_ALL_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: undefined, 
        practiceTestSchedule: undefined,
      });

    case ACTION_TYPES.SCHEDULE_TEST_GET_ALL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: undefined,
        practiceTestSchedule: formatPracticeTests(action.data.schedule, state.programs)
      });

    case ACTION_TYPES.SCHEDULE_TEST_GET_ALL_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
        practiceTestSchedule: undefined,
      });

    case ACTION_TYPES.SEND_TEST_INVITE_REQUEST:
      return Object.assign({}, state, {
        posting: true,
        error: undefined, 
        success: undefined, 
      });

    case ACTION_TYPES.SEND_TEST_INVITE_SUCCESS:
      return Object.assign({}, state, {
        posting: false,
        error: undefined, 
        success: 'Test Invite is on the way', 
      });

    case ACTION_TYPES.SEND_TEST_INVITE_FAILURE:
        return Object.assign({}, state, {
          posting: false,
          error: action.error,
          success: undefined, 
        });

    case ACTION_TYPES.GET_STUDENT_DETAILS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: undefined, 
        studentDetails: undefined,
      });

    case ACTION_TYPES.GET_STUDENT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: undefined, 
        studentDetails: {
          daysInProgram: action.data.days_in_program,
          timeTotal: action.data.time_on_site_total,
          timeRecent: action.data.time_on_site_recent,
          questionCountTotal: action.data.questions_total,
          questionCountRecent: action.data.questions_recent,
          reviewCount: action.data.reviewed_total,
          reviewTotal: action.data.reviewable_total,
          questionLog: formatQuestionLog(action.data.question_log),
          scores: action.data.scores,
          practiceTestHistory: formatPracticeTestHistory(action.data.practice_test_history),
        }
      });

    case ACTION_TYPES.GET_STUDENT_DETAILS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error, 
        studentDetails: undefined,
      });

    case ACTION_TYPES.RECOVER_LOGIN_REQUEST:
      return Object.assign({}, state, {
        sentRecoveryEmail: false
      });

    case ACTION_TYPES.RECOVER_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        sentRecoveryEmail: true 
      });

    case ACTION_TYPES.PROGRAM_SUPPORTER_REQUEST:
      return Object.assign({}, state, {
        posting: true,
        error: undefined, 
        programSupporterSuccess: false,
        success: undefined, 
      });

    case ACTION_TYPES.PROGRAM_SUPPORTER_SUCCESS:
      return Object.assign({}, state, {
        posting: false,
        error: undefined, 
        programSupporterSuccess: true,
        success: 'All Saved!', 
      });

    case ACTION_TYPES.PROGRAM_SUPPORTER_FAILURE:
      return Object.assign({}, state, {
        posting: false,
        error: action.error,
        programSupporterSuccess: false,
        success: undefined, 
      });

    case ACTION_TYPES.PROGRAM_STUDENT_REQUEST:
      return Object.assign({}, state, {
        posting: true,
        error: undefined, 
        programStudentSuccess: false,
        success: undefined, 
      });

    case ACTION_TYPES.PROGRAM_STUDENT_SUCCESS:
      return Object.assign({}, state, {
        posting: false,
        error: undefined, 
        programStudentSuccess: true,
        success: 'All Saved!', 
      });

    case ACTION_TYPES.PROGRAM_STUDENT_FAILURE:
      return Object.assign({}, state, {
        posting: false,
        programStudentSuccess: false,
        error: action.error,
        success: undefined,
      });

    case ACTION_TYPES.IMPORT_ROSTER_REQUEST:
      return Object.assign({}, state, {
        posting: true,
        error: undefined, 
        importStudentSuccess: false,
        success: undefined, 
      });

    case ACTION_TYPES.IMPORT_ROSTER_SUCCESS:
      return Object.assign({}, state, {
        posting: false,
        error: undefined, 
        importStudentSuccess: true,
        success: 'All Saved!', 
      });

    case ACTION_TYPES.IMPORT_ROSTER_FAILURE:
      return Object.assign({}, state, {
        posting: false,
        error: action.error,
        importStudentSuccess: false,
        success: undefined,
      });

    default:
      return state
  }
}
