import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './VerifyUser.module.scss';
import { userVerify } from '../../redux/actions';
import ErrorMessage from './../ErrorMessage';
import AppTitle from './../AppTitle';
import WaitForIt from '../WaitForIt';
import ButtonLinkTo from '../ButtonLinkTo';

class VerifyUser extends React.Component {

  componentDidUpdate() {

    const { isValidUserID } = this.props;
    if (isValidUserID) {
      this.props.history.replace('/home'); 
    }
  }

  componentDidMount() {
    // if there is a current user logged in then skip this
    // else validate this uuid
    const { isValidUserID, userVerify } = this.props;
    const { uuid } = this.props.match.params;

    userVerify(uuid);
  }

  render(){
    const { isValidatingUserID, isValidUserID, error} = this.props;
  
    if (isValidatingUserID)
      return <ValidateUser />
    
      if (!isValidUserID)
      return <InvalidUser error={error} />

    return <div>something went wrong</div>;
  }
}

function ValidateUser() {
  return (
    <div className={styles.verifyUser}>
      <WaitForIt waitingMessage='authenticating'></WaitForIt>
    </div>);
}

function InvalidUser(props) {
  console.log('InvalidUser ', props);
  return (
    <div className={styles.verifyUser}>
      <div className={styles.mainContainer}>
        <AppTitle />

        <div className={styles.formContainer}>
          <div className={styles.info}>We could not locate your account</div>
          <ErrorMessage errors={props.error}></ErrorMessage>
          <ButtonLinkTo linkTo='/lostpassword' text='I Lost My Login'/>
          <div className={styles.helpInfo}>If you need help email</div>
          <a href={'mailto:happy@testive.com'}>happy@testive.com</a>
        </div>
      </div>
    </div>
  );
}

function NoUserID() {
  console.log('No USER ');
  return (
    <div className={styles.verifyUser}>
      <div className={styles.mainContainer}>
        <AppTitle />

        <div className={styles.formContainer}>
          <div className={styles.info}>Please use your personalized app link</div>
          <div className={styles.helpInfo}>If you need help email</div>
          <div className={styles.helpInfo}>If you need help email</div>
          <a href={'mailto:maryb@alisteducation.com'}>maryb@alisteducation.com</a>
        </div>
      </div>
    </div>
  );
}

VerifyUser.propTypes = {
  userVerify: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isValidatingUserID: PropTypes.bool.isRequired,
  isValidUserID: PropTypes.bool.isRequired,
  error: PropTypes.string
};

function mapStateToProps(state) {
  return {
    isValidatingUserID: state.isAuthenticating,
    isValidUserID: state.isAuthenticated,
    error: state.userVerifyError
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userVerify: (userUUID) => dispatch(userVerify(userUUID))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);