import { ACTION_TYPES, ERRORS } from './constants';
import { formatISO } from 'date-fns';

const server = 'https://app.testive.com';
// const server = 'http://127.0.0.1:8000';

const allActions = {
  logout,
  getSelectedProgram,
  getDashboard,
  getStudentDetails,
  scheduledPracticeTestGetAll,
  scheduledPracticeTestAdd, 
  scheduledPracticeTestUpdate,
  scheduledPracticeTestDelete, 
  getItemAnalysisReport,
  getStudentPracticeTestReport,
  importStudentRoster,
  programSupporterAdd,
  programSupporterDelete,
  programStudentAdd,
  programStudentDelete,
  clearErrorSuccess,
}
export default allActions;

function clearErrorSuccess() {
  return {
    type: ACTION_TYPES.CLEAR_ERROR_SUCCESS,
  }
}
function formatDateForV3(date) {
  return formatISO(date, formatISO);
}

function getAppDataRequest() {
  return {
    type: ACTION_TYPES.GET_ANALYTICS_REQUEST,
  }
}

function getAppDataFailure(error) {
  return {
    type: ACTION_TYPES.GET_ANALYTICS_FAILURE,
    error: error,
  };
}

function getAppDataSuccess(data) {
  return {
    type: ACTION_TYPES.GET_ANALYTICS_SUCCESS,
    data: data,
  };
}

function getDashboardRequest() {
  return {
    type: ACTION_TYPES.GET_DASHBOARD_REQUEST,
  }
}

function getDashboardFailure(error) {
  return {
    type: ACTION_TYPES.GET_DASHBOARD_FAILURE,
    error: error,
  };
}

function getDashboardSuccess(data) {
  return {
    type: ACTION_TYPES.GET_DASHBOARD_SUCCESS,
    data: data,
  };
}

export function getDashboard(userUUID, programID) {
  return function(dispatch) {
    dispatch(getDashboardRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let requestData = [];
    requestData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    requestData.push(encodeURIComponent('program_id') + '=' + encodeURIComponent(programID));
 
    return fetch(server + '/api/reporting/analytics/dashboard/?' + requestData.join('&'), {
      method: 'GET',
      headers: header,
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(getDashboardSuccess(data.data));
        }
        else {
          dispatch(getDashboardFailure(data.msg));
        }
      },
      () => dispatch(getDashboardFailure(ERRORS.SERVER_ERROR))
    );
  };
}

function getSelectedProgramRequest() {
  return {
    type: ACTION_TYPES.GET_SELECTED_PROGRAM_REQUEST,
  }
}

function getSelectedProgramFailure(error) {
  return {
    type: ACTION_TYPES.GET_SELECTED_PROGRAM_FAILURE,
    error: error,
  };
}

function getSelectedProgramSuccess(data) {
  console.log('getSelectedProgramSuccess', data)
  return {
    type: ACTION_TYPES.GET_SELECTED_PROGRAM_SUCCESS,
    data: data,
  };
}

export function getSelectedProgram(userUUID, programID) {
  return function(dispatch) {
    dispatch(getSelectedProgramRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let requestData = [];
    requestData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    requestData.push(encodeURIComponent('program_id') + '=' + encodeURIComponent(programID));
 
    return fetch(server + '/api/reporting/analytics/program/get/?' + requestData.join('&'), {
      method: 'GET',
      headers: header,
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(getSelectedProgramSuccess(data.data));
        }
        else {
          dispatch(getSelectedProgramFailure(data.msg));
        }
      },
      () => dispatch(getSelectedProgramFailure(ERRORS.SERVER_ERROR))
    );
  };
}

function getReportRequest() {

  return {
    type: ACTION_TYPES.GET_REPORT_REQUEST,
  };
}

function getReportFailure(error) {
  return {
    type: ACTION_TYPES.GET_REPORT_FAILURE,
    error: error,
  };
}

function getReportSuccess(data, reportHeader) {
  return {
    type: ACTION_TYPES.GET_REPORT_SUCCESS,
    data: data,
    reportHeader: reportHeader,
  };
}

export function getItemAnalysisReport(userUUID, program, practiceTestName) {
  return function(dispatch) {

    dispatch(getReportRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let requestData = [];
    requestData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    requestData.push(encodeURIComponent('program_id') + '=' + encodeURIComponent(program.ID));
    requestData.push(encodeURIComponent('practice_test_name') + '=' + encodeURIComponent(practiceTestName));
  
    let reportHeader = {
      program: program.name,
      practiceTest: practiceTestName
    };
    console.log( 'dfasdfdsafdsafds', reportHeader);

    console.log(server + '/api/reporting/analytics/item-analytics/?' + requestData.join('&'));

    return fetch(server + '/api/reporting/analytics/item-analytics/?' + requestData.join('&'), {
      method: 'GET',
      headers: header,
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(getReportSuccess(data.data, reportHeader));
        }
        else {
          dispatch(getReportFailure(data.msg));
        }
      },
      () => dispatch(getReportFailure(ERRORS.SERVER_ERROR))
    );
  };
}

export function getClassSummaryReport(userUUID, program) {
  return function(dispatch) {

    dispatch(getReportRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let requestData = [];
    requestData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    requestData.push(encodeURIComponent('program_id') + '=' + encodeURIComponent(program));
    // requestData.push(encodeURIComponent('practice_test_names') + '=' + encodeURIComponent(practiceTestName));
  
    console.log(server + '/api/reporting/analytics/class-summary/?' + requestData.join('&'));

    return fetch(server + '/api/reporting/analytics/class-summary/?' + requestData.join('&'), {
      method: 'GET',
      headers: header,
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(getReportSuccess(data.data));
        }
        else {
          dispatch(getReportFailure(data.msg));
        }
      },
      () => dispatch(getReportFailure(ERRORS.SERVER_ERROR))
    );
  };
}

export function getStudentPracticeTestReport(userUUID, studentUUID, practiceTestName) {
  return function(dispatch) {

    dispatch(getReportRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let requestData = [];
    requestData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    requestData.push(encodeURIComponent('student_uuid') + '=' + encodeURIComponent(studentUUID));
    requestData.push(encodeURIComponent('practice_test_name') + '=' + encodeURIComponent(practiceTestName));
  
    let reportHeader = {
      practiceTest: practiceTestName
    };
    console.log( 'dfasdfdsafdsafds', reportHeader);

    console.log(server + '/api/reporting/analytics/student-practice-test-report/?' + requestData.join('&'));

    return fetch(server + '/api/reporting/analytics/student-practice-test-report/?' + requestData.join('&'), {
      method: 'GET',
      headers: header,
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          console.log('student report ', data);
          dispatch(getReportSuccess(data.data, reportHeader));
        }
        else {
          dispatch(getReportFailure(data.msg));
        }
      },
      () => dispatch(getReportFailure(ERRORS.SERVER_ERROR))
    );
  };
}

function userVerifyRequest() {
  return {
    type: ACTION_TYPES.USER_VERIFY_REQUEST
  };
}

function userVerifySuccess(data) {
  return {
    type: ACTION_TYPES.USER_VERIFY_SUCCESS,
    receivedAt: Date.now(),
    data: data,
  };
}

function userVerifyFailure(error) {
  return {
    type: ACTION_TYPES.USER_VERIFY_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function userVerify(userUUID) {
  return function(dispatch) {
    dispatch(userVerifyRequest());
    dispatch(fetchUserData(userUUID));
  };
}

function fetchUserData(userUUID) {
  return function(dispatch) {
    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let requestData = [];
    requestData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
 
    return fetch(server + '/api/reporting/analytics/login/?' + requestData.join('&'), {
      method: 'GET',
      headers: header,
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(userVerifySuccess(data.data));
          dispatch(getSelectedProgram(userUUID, data.data.programs[0].id));
        }
        else {
          dispatch(userVerifyFailure(data.msg));
        }
      },
      () => dispatch(userVerifyFailure(ERRORS.SERVER_ERROR))
    );
  };
}

function recoverLoginRequest() {
  return {
    type: ACTION_TYPES.RECOVER_LOGIN_REQUEST,
    receivedAt: Date.now()
  };
}

function recoverLoginSuccess() {
  return {
    type: ACTION_TYPES.RECOVER_LOGIN_SUCCESS,
    receivedAt: Date.now()
  };
}

function recoverLoginFailure(error) {
  return {
    type: ACTION_TYPES.RECOVER_LOGIN_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function recoverLogin(email) {
  return function(dispatch) {
    dispatch(recoverLoginRequest());
    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let requestData = [];
    requestData.push(encodeURIComponent('email') + '=' + encodeURIComponent(email));
 
    return fetch(server + '/api/reporting/analytics/password/?' + requestData.join('&'), {
      method: 'GET',
      headers: header,
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(recoverLoginSuccess());
        }
        else {
          dispatch(recoverLoginFailure(data.msg));
        }
      },
      () => dispatch(userVerifyFailure(ERRORS.SERVER_ERROR))
    );
  };
}

export function logout() {
  return {
    type: ACTION_TYPES.USER_LOGOUT
  }
}

function schedulePracticeTestGetAllRequest() {
  return {
    type: ACTION_TYPES.SCHEDULE_TEST_GET_ALL_REQUEST
  };
}

function schedulePracticeTestGetAllSuccess(data) {
  return {
    type: ACTION_TYPES.SCHEDULE_TEST_GET_ALL_SUCCESS,
    receivedAt: Date.now(),
    data: data,
  };
}

function schedulePracticeTestGetAllFailure(error) {
  return {
    type: ACTION_TYPES.SCHEDULE_TEST_GET_ALL_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function scheduledPracticeTestGetAll(userUUID) {
  return function (dispatch) {
    dispatch(schedulePracticeTestGetAllRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let requestData = [];
    requestData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
 
    return fetch(server + '/api/programs/test-schedule/get/?' + requestData.join('&'), {
      method: 'GET',
      headers: header,
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(schedulePracticeTestGetAllSuccess(data.data));
        }
        else {
          dispatch(schedulePracticeTestGetAllFailure(data.msg));
        }
      },
      () => dispatch(schedulePracticeTestGetAllFailure(ERRORS.SERVER_ERROR))
    );
  };
}

function schedulePracticeTestRequest() {
  return {
    type: ACTION_TYPES.SCHEDULE_TEST_REQUEST
  };
}

function schedulePracticeTestSuccess(data) {
  return {
    type: ACTION_TYPES.SCHEDULE_TEST_SUCCESS,
    receivedAt: Date.now(),
    data: data,
  };
}

function schedulePracticeTestFailure(error) {
  return {
    type: ACTION_TYPES.SCHEDULE_TEST_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function scheduledPracticeTestAdd(userUUID, programID, testDate, practiceTest) {
  return function (dispatch) {
    dispatch(schedulePracticeTestRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('program_id') + '=' + encodeURIComponent(programID));
    postData.push(encodeURIComponent('test_date') + '=' + encodeURIComponent(formatDateForV3(testDate)));
    postData.push(encodeURIComponent('practice_test') + '=' + encodeURIComponent(practiceTest));
  
    console.log(postData.join('&'));
    return fetch(server+'/api/programs/test-schedule/add/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(schedulePracticeTestSuccess(data.data));
        }
        else {
          dispatch(schedulePracticeTestFailure(data.msg));
        }
      },
      () => dispatch(schedulePracticeTestFailure(ERRORS.SERVER_ERROR))
    );
  };
}

export function scheduledPracticeTestUpdate(userUUID, scheduleID, testDate, practiceTest) {
  return function (dispatch) {
    dispatch(schedulePracticeTestRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('schedule_id') + '=' + encodeURIComponent(scheduleID));
    postData.push(encodeURIComponent('test_date') + '=' + encodeURIComponent(formatDateForV3(testDate)));
    postData.push(encodeURIComponent('practice_test') + '=' + encodeURIComponent(practiceTest));
  
    console.log('scheduledPracticeTestUpdate ', postData.join('&'))
    return fetch(server+'/api/programs/test-schedule/edit/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(schedulePracticeTestSuccess(data.data));
        }
        else {
          dispatch(schedulePracticeTestFailure(data.msg));
        }
      },
      () => dispatch(schedulePracticeTestFailure(ERRORS.SERVER_ERROR))
    );
  };
}

export function scheduledPracticeTestDelete(userUUID, scheduleID) {
  return function (dispatch) {
    dispatch(schedulePracticeTestRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('schedule_id') + '=' + encodeURIComponent(scheduleID));
  
    console.log('scheduledPracticeTestDelete ', postData.join('&'))
    return fetch(server+'/api/programs/test-schedule/delete/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(schedulePracticeTestSuccess(data.data));
        }
        else {
          dispatch(schedulePracticeTestFailure(data.msg));
        }
      },
      () => dispatch(schedulePracticeTestFailure(ERRORS.SERVER_ERROR))
    );
  };
}

function sendTestInviteRequest() {
  return {
    type: ACTION_TYPES.SEND_TEST_INVITE_REQUEST
  };
}

function sendTestInviteSuccess(data) {
  return {
    type: ACTION_TYPES.SEND_TEST_INVITE_SUCCESS,
    receivedAt: Date.now(),
    data: data,
  };
}

function sendTestInviteFailure(error) {
  return {
    type: ACTION_TYPES.SEND_TEST_INVITE_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function scheduledPracticeTestSendInvite(userUUID, studentEmail) {
  return function (dispatch) {
    dispatch(sendTestInviteRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('student_email') + '=' + encodeURIComponent(studentEmail));
  
    return fetch(server+'/api/programs/test-schedule/invite/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(sendTestInviteSuccess(data.data));
        }
        else {
          dispatch(sendTestInviteFailure(data.msg));
        }
      },
      () => dispatch(sendTestInviteFailure(ERRORS.SERVER_ERROR))
    );
  };
}

function getStudentDetailsRequest() {

  return {
    type: ACTION_TYPES.GET_STUDENT_DETAILS_REQUEST,
  };
}

function getStudentDetailsFailure(error) {
  return {
    type: ACTION_TYPES.GET_STUDENT_DETAILS_FAILURE,
    error: error,
  };
}

function getStudentDetailsSuccess(data) {
  return {
    type: ACTION_TYPES.GET_STUDENT_DETAILS_SUCCESS,
    data: data,
  };
}

export function getStudentDetails(userUUID, studentUUID, testType) {
  return function(dispatch) {

    console.log('getStudentDetails', testType);
    dispatch(getStudentDetailsRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let requestData = [];
    requestData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    requestData.push(encodeURIComponent('student_uuid') + '=' + encodeURIComponent(studentUUID));
    requestData.push(encodeURIComponent('test_type') + '=' + encodeURIComponent(testType));

    return fetch(server + '/api/reporting/analytics/student/get/?' + requestData.join('&'), {
      method: 'GET',
      headers: header,
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(getStudentDetailsSuccess(data.data));
        }
        else {
          dispatch(getStudentDetailsFailure(data.msg));
        }
      },
      () => dispatch(getStudentDetailsFailure(ERRORS.SERVER_ERROR))
    );
  };
}

function importStudentRosterRequest() {
  return {
    type: ACTION_TYPES.IMPORT_ROSTER_REQUEST
  };
}

function importStudentRosterSuccess(data) {
  return {
    type: ACTION_TYPES.IMPORT_ROSTER_SUCCESS,
    receivedAt: Date.now(),
    data: data,
  };
}

function importStudentRosterFailure(error) {
  return {
    type: ACTION_TYPES.IMPORT_ROSTER_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}


export function importStudentRoster(userUUID, programID, students) {
  return function (dispatch) {
    dispatch(importStudentRosterRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('program_id') + '=' + encodeURIComponent(programID));
    postData.push(encodeURIComponent('students') + '=' + encodeURIComponent(JSON.stringify(students)));
 
    console.log(postData.join('&'));

    return fetch(server+'/api/reporting/import_students/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(importStudentRosterSuccess(data.data));
        }
        else {
          dispatch(importStudentRosterFailure(data.msg));
        }
      },
      () => dispatch(importStudentRosterFailure(ERRORS.SERVER_ERROR))
    );
  };
}


function programSupporterRequest() {
  return {
    type: ACTION_TYPES.PROGRAM_SUPPORTER_REQUEST
  };
}

function programSupporterSuccess(data) {
  return {
    type: ACTION_TYPES.PROGRAM_SUPPORTER_SUCCESS,
    receivedAt: Date.now(),
    data: data,
  };
}

function programSupporterFailure(error) {
  return {
    type: ACTION_TYPES.PROGRAM_SUPPORTER_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function programSupporterAdd(userUUID, programID, firstName, lastName, email) {
  return function (dispatch) {
    dispatch(programSupporterRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('program_id') + '=' + encodeURIComponent(programID));
    postData.push(encodeURIComponent('first_name') + '=' + encodeURIComponent(firstName));
    postData.push(encodeURIComponent('last_name') + '=' + encodeURIComponent(lastName));
    postData.push(encodeURIComponent('email') + '=' + encodeURIComponent(email));
  
    console.log(postData.join('&'));

    return fetch(server+'/api/programs/program-supporter/add/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(programSupporterSuccess(data.data));
        }
        else {
          dispatch(programSupporterFailure(data.msg));
        }
      },
      () => dispatch(programSupporterFailure(ERRORS.SERVER_ERROR))
    );
  };
}

// export function programSupporterUpdate(userUUID, scheduleID, testDate, practiceTest) {
//   return function (dispatch) {
//     dispatch(schedulePracticeTestRequest());

//     let header = new Headers({
//       'Content-Type': 'application/x-www-form-urlencoded'
//     });

//     let postData = [];
//     postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
//     postData.push(encodeURIComponent('schedule_id') + '=' + encodeURIComponent(scheduleID));
//     postData.push(encodeURIComponent('test_date') + '=' + encodeURIComponent(formatDateForV3(testDate)));
//     postData.push(encodeURIComponent('practice_test') + '=' + encodeURIComponent(practiceTest));
  
//     console.log('scheduledPracticeTestUpdate ', postData.join('&'))
//     return fetch(server+'/api/programs/test-schedule/edit/', {
//       method: 'POST',
//       body: postData.join('&'),
//       headers: header
//     }).then(response => response.json())
//     .then(
//       data => {
//         if(data.status === 'ok') {
//           dispatch(schedulePracticeTestSuccess(data.data));
//         }
//         else {
//           dispatch(schedulePracticeTestFailure(data.msg));
//         }
//       },
//       error => dispatch(schedulePracticeTestFailure(error))
//     );
//   };
// }

export function programSupporterDelete(userUUID, programID, email) {
  return function (dispatch) {
    dispatch(programSupporterRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('program_id') + '=' + encodeURIComponent(programID));
    postData.push(encodeURIComponent('email') + '=' + encodeURIComponent(email));
  
    console.log('programSupporterDelete ', postData.join('&'))
    return fetch(server+'/api/programs/program-supporter/delete/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(programSupporterSuccess(data.data));
        }
        else {
          dispatch(programSupporterFailure(data.msg));
        }
      },
      () => dispatch(programSupporterFailure(ERRORS.SERVER_ERROR))
    );
  };
}

function programStudentRequest() {
  return {
    type: ACTION_TYPES.PROGRAM_STUDENT_REQUEST
  };
}

function programStudentSuccess(data) {
  return {
    type: ACTION_TYPES.PROGRAM_STUDENT_SUCCESS,
    receivedAt: Date.now(),
    data: data,
  };
}

function programStudentFailure(error) {
  return {
    type: ACTION_TYPES.PROGRAM_STUDENT_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function programStudentAdd(userUUID, programID, firstName, lastName, email) {
  return function (dispatch) {
    dispatch(programStudentRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('program_id') + '=' + encodeURIComponent(programID));
    postData.push(encodeURIComponent('first_name') + '=' + encodeURIComponent(firstName));
    postData.push(encodeURIComponent('last_name') + '=' + encodeURIComponent(lastName));
    postData.push(encodeURIComponent('email') + '=' + encodeURIComponent(email));
  
    console.log(postData.join('&'));

    return fetch(server+'/api/programs/program-student/add/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(programStudentSuccess(data.data));
        }
        else {
          dispatch(programStudentFailure(data.msg));
        }
      },
      () => dispatch(programStudentFailure(ERRORS.SERVER_ERROR))
    );
  };
}

export function programStudentDelete(userUUID, programID, email) {
  return function (dispatch) {
    dispatch(programStudentRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('program_id') + '=' + encodeURIComponent(programID));
    postData.push(encodeURIComponent('email') + '=' + encodeURIComponent(email));
  
    return fetch(server+'/api/programs/program-student/delete/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(response => response.json())
    .then(
      data => {
        if(data.status === 'ok') {
          dispatch(programStudentSuccess(data.data));
        }
        else {
          dispatch(programStudentFailure(data.msg));
        }
      },
      () => dispatch(programStudentFailure(ERRORS.SERVER_ERROR))
    );
  };
}

