import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams} from 'react-router';
import allActions from '../../redux/actions';
import ButtonLinkTo from '../ButtonLinkTo';
import HomeLayoutContainer from '../HomeLayoutContainer';
import styles from './PracticeTestHome.module.scss';
import PracticeTestEvents from '../PracticeTestAdmin/PracticeTestEvents';
import PracticeTestEventAddEdit from '../PracticeTestAdmin/PracticeTestEventAddEdit';
import PracticeTestEventDetails from '../PracticeTestAdmin/PracticeTestEventDetails/PracticeTestEventDetails';
import WaitForIt from '../WaitForIt';
import ErrorMessage from '../ErrorMessage';


function PracticeTestHome(props) {

  const admin = useSelector(state=>state.admin);
  const programs = useSelector(state=>state.programs);
  const practiceTestSchedule = useSelector(state=>state.practiceTestSchedule);
  const loading = useSelector(state=>state.loading);
  const error = useSelector(state=>state.error);
  const userUUID = useSelector(state=>state.userUUID);
  const dispatch = useDispatch();

  const [viewMode, setViewMode] = useState('view');
  const [selected, setSelected] = useState();
  const { mode, id } = useParams();

  useEffect(() => {
    console.log('useEffect practice test home effect initial load')
    dispatch(allActions.scheduledPracticeTestGetAll(userUUID));
  }, [userUUID]);

  useEffect(() => {
    console.log('useEffect url params ', mode, id, practiceTestSchedule);
    setViewMode(mode ? mode : 'view');

    if (id && practiceTestSchedule) {
      let schedule = practiceTestSchedule.find(x => x.ID.toString()===id);
      setSelected(schedule);
    }
    else {
      setSelected();
    }

  }, [mode, id, practiceTestSchedule]);

  if (error)  {
    const errorDisplay = <ErrorMessage errors={error}></ErrorMessage>
    return (
      <div className={styles.practiceTestHome}>
        <HomeLayoutContainer 
          rightSideContent={errorDisplay}
          showTopNavBar={false} 
          titleBarText='Practice Test Admin'/>
      </div>
    );
  }
  else if (loading || !practiceTestSchedule)  {
    const loadingDisplay = <WaitForIt waitingMessage={'Loading Practice Test Schedules'}></WaitForIt>
    return (
      <div className={styles.practiceTestHome}>
        <HomeLayoutContainer 
          rightSideContent={loadingDisplay}
          showTopNavBar={false} 
          titleBarText='Practice Test Admin'/>
      </div>
    );
  }
  else if (viewMode==='add') {
    console.log('render add');
    const addNewModal = <PracticeTestEventAddEdit/>
    return (
      <div className={styles.practiceTestHome}>
        <HomeLayoutContainer 
          rightSideContent={addNewModal}
          showTopNavBar={false} 
          titleBarText='Practice Test Admin'/>
      </div>);
  }
  else if (viewMode==='edit') {
    console.log('render edit');
    const editModal = <PracticeTestEventAddEdit scheduledPracticeTest={selected}/>
    return (
      <div className={styles.practiceTestHome}>
        <HomeLayoutContainer 
          rightSideContent={editModal}
          showTopNavBar={false} 
          titleBarText='Practice Test Admin'/>
      </div>);
  }
  else if (viewMode==='view' && selected) {
    console.log('render view single');
    const viewSingle = (
      <div className={styles.container}>
        <PracticeTestEventDetails practiceTestEvent={selected} />
      </div>);
    return (
      <div className={styles.practiceTestHome}>
        <HomeLayoutContainer 
          rightSideContent={viewSingle}
          showTopNavBar={false} 
          titleBarText='Practice Test Admin'/>
      </div>);
  }
  else {
    console.log('render view all');
    const viewAllDisplay = (
      <div className={styles.container}>
        <div className={styles.scheduleHeader}>
          { admin ?
            <div className={styles.gridButtons}>
              <ButtonLinkTo linkTo='/practice-test-admin/add/' text='schedule a practice test'></ButtonLinkTo> 
            </div>
            : null }
        </div>
        <PracticeTestEvents 
          admin={admin}
          testEvents={practiceTestSchedule} 
          programs={programs}/>
      </div>); 
    return (
      <div className={styles.practiceTestHome}>
        <HomeLayoutContainer 
          rightSideContent={viewAllDisplay}
          showTopNavBar={false} 
          titleBarText='Practice Test Admin'/>
      </div>);
  }
}

export default PracticeTestHome;
