import React from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ErrorMessage from '../ErrorMessage';
import WaitForIt from '../WaitForIt';
import styles from './StudentList.module.scss';

function StudentItem({student}) {
  const history = useHistory()
  const onClick = () => {
    history.push('/students/'+student.UUID);
  }

  return (
    <tr 
      className={styles.studentItem} 
      onClick={(e) => onClick(student.UUID)}>
      <td>{student.firstName}</td>
      <td>{student.lastName}</td>
      <td>{student.email}</td>
    </tr>
  );
}

function StudentList() {
  const program = useSelector(state=>state.program);
  const students = program.students.map((s, index) => {
    return (
      <StudentItem
        key={index} 
        student={s}>
      </StudentItem>     
      );}
  );

  const loading = useSelector(state=>state.loading);
  const error = useSelector(state=>state.error);

  if (loading) {
    return <WaitForIt waitingMessage='loading student list' />
  }
  if (error) {
    return <ErrorMessage errors={error} />
  }
  return (
    <div className={styles.studentList}>
      <table>
        <thead>
          <tr>
            <th>First</th>
            <th>Last</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {students}
        </tbody>
      </table>
    </div>

  );
}

export default StudentList;