import React from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../redux/actions';
import SideNavBarItem from './SideNavBarItem';
import Images from './../../assets/Images';
import styles from './SideNavBar.module.scss';

function SideNavBar() {
  const admin = useSelector(state=>state.admin);
  const userName = useSelector(state=>state.userName);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(allActions.logout());
    history.replace('/');
    window.location.reload();
  }
  
  return (
    <div className={styles.sideNavBar}>
      <div className={styles.navTop}>
        <SideNavBarItem text='home' image={Images.home} linkTo='/home'/>
        <SideNavBarItem text='reports' linkTo='/reports'/>
        <SideNavBarItem text='practice tests' linkTo='/practice-test-admin/view'/>
        <SideNavBarItem text='students' linkTo='/students'/>
      </div>
      <div className={styles.navBottom}>
        {admin ? <SideNavBarItem text='admin' linkTo='/admin'/> : null }
        <SideNavBarItem text={userName}/>
        <SideNavBarItem text='logout' onClick={handleLogout}/>
        {/* <img className={styles.logo} width={70} src={Images.appTitleLogo} alt='logo'/> */}
      </div>
    </div>
  );
}


export default SideNavBar;