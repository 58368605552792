import React from 'react';
import PropTypes from 'prop-types';

import styles from './ReportLink.module.scss';

function ReportLink(props) {
  
  const { reportName, reportDescription, reportID, onSelect } = props;

  return (
    <div className={styles.reportLink} onClick={(e) => onSelect(reportID)} >
      <div className={styles.reportInfo}>
        <div className={styles.title}>{reportName}</div>
        <div className={styles.reportDescription}>{reportDescription}</div>
      </div>
    </div>
  );
  
}

ReportLink.propTypes = {
  reportName: PropTypes.string.isRequired,
  reportDescription: PropTypes.string.isRequired,
  reportID: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default ReportLink;