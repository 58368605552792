import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import SelectOption from './SelectOption';
import allActions from '../../redux/actions';
import styles from './ProgramSelector.module.scss';
import Images from './../../assets/Images';

function ProgramSelector(props) {
  const node = useRef();
  const [showDropDown, setShowDropDown] = useState(false);
  const [items, setItems] = useState([]);
  const programs = useSelector(state=>state.programs);
  const program = useSelector(state=>state.program);
  const userUUID = useSelector(state=>state.userUUID);
  const dispatch = useDispatch();

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click 
    setShowDropDown(false);
  };

  const handleChange = programID => {
    dispatch(allActions.getSelectedProgram(userUUID, programID));
    setShowDropDown(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    console.log('useEffect refill program dropdown');
    if (programs) {
      setItems(programs.map(( p, index ) => (
        <SelectOption
          onClick={handleChange}
          key={index} 
          value={p.ID} 
          text={p.name} />)));}
  }, [programs]);

  return ( 
    <div ref={node} className={styles.programSelector}>
      <div className={styles.selectedDisplay}>
        <div className={styles.selectedOptionText}>{program.name}</div>
        <div className={styles.showMenuButton}>
          <img src={Images.downArrow} alt="program drop down" onClick={() => setShowDropDown(!showDropDown)} />
        </div>
      </div>
        { showDropDown 
          ? <div className={styles.dropDownContainer}>
              <div className={styles.dropDownList}>{items}</div>
            </div>
          : null
        }
    </div>
  );
}

export default ProgramSelector;