import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../redux/actions';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { CSVLink } from 'react-csv';
import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';
import SuccessMessage from '../../SuccessMessage';
import styles from './PracticeTestEventDetails.module.scss';
// import Images from './../../../assets/Images';

function PracticeTestEventDetails(props) {
  const userUUID = useSelector(state=>state.userUUID);
  const posting = useSelector(state=>state.posting);
  const error = useSelector(state=>state.error);
  const success = useSelector(state=>state.success);
  const dispatchAction = useDispatch();

  const { practiceTestEvent } = props;
  const program = useSelector(state=>state.program);

  const studentExportData = program.students.map((s) => {
    return ([
      s.lastName,
      s.firstName,
      s.email,
      'https://practicetest.testive.com/verify/'+s.UUID+'/'+practiceTestEvent.practiceTest
    ]);}
  );

  const onClickSend = email => {
    if (!posting) {
      dispatchAction(allActions.scheduledPracticeTestSendInvite(userUUID, email));
    }
  };

  const errorDisplay = (error) 
    ? <ErrorMessage errors={error} />
    : null;

  const successfulSaveDisplay = (success)
    ? <SuccessMessage message={'Invite request sent to hubspot'} />
    : null;

  const studentRows = program.students.map((s, index) => {
    let testLink = 'https://practicetest.testive.com/verify/'+s.UUID+'/'+practiceTestEvent.practiceTest;
    return (
      <tr key={index}>
        <td>{s.lastName}</td>
        <td>{s.firstName}</td>
        <td>{s.email}</td>
        <td><a href={testLink} rel="noopener noreferrer" target='_blank'>{s.firstName}_TestLINK</a></td>
        <td><Button smallButton={true} onClick={(email)=>onClickSend(s.email)}>send</Button></td>
      </tr>     
      );}
  );
  const filename = practiceTestEvent.programName + '_' + practiceTestEvent.practiceTest +'_TestLinks.csv'; 
  
  return (
    <div className={styles.practiceTestEventDetails}>
      <div className={styles.headerContainer}>
        <div className={styles.textBlock}>
          <div><span>Program:</span> {practiceTestEvent.programName}</div> 
          <div><span>Test:</span> {practiceTestEvent.practiceTest}</div>
          <div><span>When:</span> {format(parseISO(practiceTestEvent.date),'EEEE, MMMM do h:mm a')}</div>
        </div>
        <div className={styles.exportButtons}>
          <CSVLink 
            data={studentExportData} 
            headers={['First', 'Last', 'Email', 'Test Link']}
            filename={filename}
            className={styles.exportButton}>
            export
          </CSVLink>
          {/* <img src={Images.export} alt='export report'></img> */}
        </div>
      </div>
      <div className={styles.postResponse}>
          {successfulSaveDisplay}
          {errorDisplay}
      </div>
      <div className={styles.studentList}>
        <table>
          <thead>
            <tr>
              <th>First</th>
              <th>Last</th>
              <th>Email</th>
              <th>Test Link</th>
              <th>Invite</th>
            </tr>
          </thead>
          <tbody>
            {studentRows}
          </tbody>
        </table>
      </div>
    </div>
  );
}


PracticeTestEventDetails.propTypes = {
  practiceTestEvent: PropTypes.object.isRequired
};

export default PracticeTestEventDetails;
