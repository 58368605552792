import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorMessage.module.scss';
import Images from '../../assets/Images';

function ErrorMessage(props) {
  const { title, errors } = props;

  if (!errors || errors.length === 0) {
    return null;
  }
  else {
    return (
      <div className={styles.errorMessage}>
        <img className={styles.errorIcon} src={Images.error} alt='error'></img>
        <div className={styles.error}>
          <div className={styles.errorTitle}>
            {title}
          </div>
          <div className={styles.errorList}>
            {renderErrors()}
          </div>
        </div>
      </div>
    );
  }
  
  function renderErrors() {
    var errorMessage;
    if (Array.isArray(errors)) {
      errorMessage = errors.map(function(e, i) {
        return (<span key={i} className={styles.errorText}>{e}</span>);
      });
    }
    else if (typeof errors === "string") {
      errorMessage = (<span className={styles.errorText}>{errors}</span>);
    }
    else {
      var e = errors.toString();
      errorMessage = (<span className={styles.errorText}>{e}</span>);
    }
    return errorMessage
  }
}

ErrorMessage.propTypes = {
  title: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
};

ErrorMessage.defaultProps = {
  title: '',
  errors: []
};

export default ErrorMessage; 