import React from 'react';
import PropTypes from 'prop-types';

import styles from './DashboardCardTimeOnSite.module.scss';

function FormattedDuration({seconds}) {


  const display_seconds = seconds % 60
  const minutes = Math.floor((seconds/ 1000) % 60);
  const hours = Math.floor((seconds/ (1000 * 60)) % 60);

  return ( 
    <div className={styles.metricValue}>
      { (hours > 0) 
        ? <span className={styles.number}>{hours}<span className={styles.timeUnit}>h</span></span>
        : null
      }
      <span className={styles.number}>{minutes}<span className={styles.timeUnit}>m</span></span> 
      <span className={styles.number}>{display_seconds}<span className={styles.timeUnit}>s</span></span> 
    </div>); 
}


function DashboardCardTimeOnSite(props) {
  const { total, recent } = props;
  
  return (
    <div className={styles.dashboardCardTimeOnSite}>
      <div className={styles.title}>Time on Site</div>
      <div className={styles.content}>
        <div className={styles.metric}>
        
          <FormattedDuration seconds={recent}></FormattedDuration>
          <div className={styles.metricLabel}>last 7 days</div>
        </div>

        <div className={styles.separator}></div>

        <div className={styles.metric}>
          <FormattedDuration seconds={total}></FormattedDuration>
          <div className={styles.metricLabel}>all time</div>
        </div>
      </div>
    </div>
  );
}


DashboardCardTimeOnSite.propTypes = {
  total: PropTypes.number.isRequired,
  recent: PropTypes.number.isRequired,
};

export default DashboardCardTimeOnSite;