export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS'; 
export const USER_VERIFY_FAILURE = 'USER_VERIFY_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';

export const RECOVER_LOGIN_REQUEST = 'RECOVER_LOGIN_REQUEST';
export const RECOVER_LOGIN_SUCCESS = 'RECOVER_LOGIN_SUCCESS'; 
export const RECOVER_LOGIN_FAILURE = 'RECOVER_LOGIN_FAILURE';

export const GET_ANALYTICS_REQUEST = 'GET_ANALYTICS_REQUEST';
export const GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS';
export const GET_ANALYTICS_FAILURE = 'GET_ANALYTICS_FAILURE';

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

export const SCHEDULE_TEST_GET_REQUEST = 'SCHEDULE_TEST_GET_REQUEST';
export const SCHEDULE_TEST_GET_SUCCESS = 'SCHEDULE_TEST_GET_SUCCESS';
export const SCHEDULE_TEST_GET_FAILURE = 'SCHEDULE_TEST_GET_FAILURE';

export const SCHEDULE_TEST_GET_ALL_REQUEST = 'SCHEDULE_TEST_GET_ALL_REQUEST';
export const SCHEDULE_TEST_GET_ALL_SUCCESS = 'SCHEDULE_TEST_GET_ALL_SUCCESS';
export const SCHEDULE_TEST_GET_ALL_FAILURE = 'SCHEDULE_TEST_GET_ALL_FAILURE';

export const SCHEDULE_TEST_REQUEST = 'SCHEDULE_TEST_REQUEST';
export const SCHEDULE_TEST_SUCCESS = 'SCHEDULE_TEST_SUCCESS';
export const SCHEDULE_TEST_FAILURE = 'SCHEDULE_TEST_FAILURE';

export const PROGRAM_STUDENT_REQUEST = 'PROGRAM_STUDENT_REQUEST';
export const PROGRAM_STUDENT_SUCCESS = 'PROGRAM_STUDENT_SUCCESS';
export const PROGRAM_STUDENT_FAILURE = 'PROGRAM_STUDENT_FAILURE';
export const IMPORT_ROSTER_REQUEST = 'IMPORT_ROSTER_REQUEST';
export const IMPORT_ROSTER_SUCCESS = 'IMPORT_ROSTER_SUCCESS';
export const IMPORT_ROSTER_FAILURE = 'IMPORT_ROSTER_FAILURE';


export const PROGRAM_SUPPORTER_REQUEST = 'PROGRAM_SUPPORTER_REQUEST';
export const PROGRAM_SUPPORTER_SUCCESS = 'PROGRAM_SUPPORTER_SUCCESS';
export const PROGRAM_SUPPORTER_FAILURE = 'PROGRAM_SUPPORTER_FAILURE';

export const SEND_TEST_INVITE_REQUEST = 'SEND_TEST_INVITE_REQUEST';
export const SEND_TEST_INVITE_SUCCESS = 'SEND_TEST_INVITE_SUCCESS';
export const SEND_TEST_INVITE_FAILURE = 'SEND_TEST_INVITE_FAILURE';

export const GET_STUDENT_DETAILS_REQUEST = 'GET_STUDENT_DETAILS_REQUEST';
export const GET_STUDENT_DETAILS_SUCCESS = 'GET_STUDENT_DETAILS_SUCCESS';
export const GET_STUDENT_DETAILS_FAILURE = 'GET_STUDENT_DETAILS_FAILURE';

export const GET_SELECTED_PROGRAM_REQUEST = 'GET_SELECTED_PROGRAM_REQUEST';
export const GET_SELECTED_PROGRAM_SUCCESS = 'GET_SELECTED_PROGRAM_SUCCESS';
export const GET_SELECTED_PROGRAM_FAILURE = 'GET_SELECTED_PROGRAM_FAILURE';

export const CLEAR_ERROR_SUCCESS = 'CLEAR_ERROR_SUCCESS'