import React from 'react';
import PropTypes from 'prop-types';

import PercentileChart from '../Charts/PercentileChart';
import styles from './DashboardCardReview.module.scss';

function DashboardCardReview(props) {
  const { reviewCount, reviewTotal } = props;
  const percentReviewed = (reviewTotal > 0) 
    ? reviewCount/reviewTotal
    : null;

  return (
    <div className={styles.dashboardCardReview}>
        <PercentileChart
          percent={percentReviewed}
          chartTitle={'Questions Reviewed'}
          color={'#3B3561'}
        />      
    </div>
  );
}

DashboardCardReview.propTypes = {
  reviewCount: PropTypes.number.isRequired,
  reviewTotal: PropTypes.number.isRequired,
};

export default DashboardCardReview;