import React from 'react';
import PropTypes from 'prop-types';
import styles from './WaitForIt.module.scss';

function WaitForIt(props) {
  const { waitingMessage } = props;

  return (
      <div className={styles.waitForIt}>
        <div className={styles.waitingMessage}>{waitingMessage}</div>
        <div className={styles.spinner}>
            <div className={styles.bounce1}></div>
            <div className={styles.bounce2}></div>
            <div className={styles.bounce3}></div>
        </div>
      </div>
  );
}

WaitForIt.propTypes = {
  waitingMessage: PropTypes.string,
}

export default WaitForIt; 

