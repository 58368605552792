import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie } from 'recharts';

import styles from './PercentileChart.module.scss';

class PercentileChart extends React.Component {

  rend
  render() {
    const { percent, chartTitle, color } = this.props;

    const metricValue = percent ? Math.round(percent * 100) + ' %': '?'
    const handleNoValue = percent ? percent: 0;
  
    const data = [
      {name: 'percent', value: handleNoValue, fill: color},
      {name: 'empty', value: 1-handleNoValue, fill: color, fillOpacity:.15},
    ];

    return (
      <div className={styles.percentileChart}>
        <div className={styles.chartTitle}>{chartTitle}</div>
        <div className={styles.chartContainer}>
          <PieChart width={160} height={160}>
            <Pie 
              data={data} 
              dataKey='value'
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              stroke="circle"
              paddingAngle={0}
              >
            </Pie>
          </PieChart>
          <div className={styles.score}>{metricValue}</div>
        </div>
      </div>
    );
  }
}

PercentileChart.propTypes = {
  percent: PropTypes.number,
  chartTitle: PropTypes.string,
  color: PropTypes.string,
};

export default PercentileChart;
